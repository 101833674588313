import React from "react";
import { connect } from "react-redux";
import { toggleMenuDisplay, setFilters, setClients, setUserPreferedLanguage, setLanguageTranslations } from "../../actions/AppActions";
import { Modal, Form } from 'react-bootstrap'
import "../../App.css";
import "../../css/styles.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import Tabs from "@material-ui/core/Tabs";

import * as api from "../../services/apiAction"

import i18n from "i18next";
import { ENVIRONMENT, ADMIN, APP_VERSION, SVM_URL } from "../../StringConstants";
import { faSignOutAlt , faFileDownload} from "@fortawesome/free-solid-svg-icons";
import AppStoreB9 from "../B9Components/AppStoreB9"
import { initReactI18next } from "react-i18next";
import { confirmAlert } from 'react-confirm-alert';
import dateFormat from 'dateformat';
import TextareaAutosize from "react-textarea-autosize";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory from "react-bootstrap-table2-paginator";
import ToolkitProvider, { CSVExport } from "react-bootstrap-table2-toolkit";
import { Card, Button,  Tabs as NavTabs, Tab, OverlayTrigger, Tooltip } from "react-bootstrap";
import { faCompressAlt, faExpandAlt } from "@fortawesome/free-solid-svg-icons";
import { iwGphExclude, gph4Exclude, allGphExclude, gph4Widgets, iwGPHwidgets, tenantIdDashboards, demoTypeDashboards, drillToDashWithParentFilter } from "../../config/SisenseDashboards"
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { buttonFilters } from "../IntOps/FilterForReconHR";
import { DashboardLandingView, SummaryWheelView, DashboardDetailedView } from 'svm-dashboard';
import "svm-dashboard/dist/assets/css/Dashboard.css";
import { InteractionRequiredAuthError } from '@azure/msal-browser'
import { msalInstance } from '../../index';
import { loginRequest } from '../../services/authProvider'

const noDataMessage = {
  header: "No Client Data",
  body: "Please select Program and Reporting year."
}

const { ExportCSVButton } = CSVExport;
class B9MenuComponent extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      showPopUp: false,
      showPopUpAppStore: false,
      hideProfileHolderDiv: true,
      hideLanguageDiv: true,
      openedDropdown: "",
      loading: false,
      languageList: [],
      languageListFiltered: [],
      searchedLanguageText: '',
      selectedLanguage: 'Select Language',
      showDefault: true,
      showDashFilt: false,
      showDashFiltModal: false,
      tabParentId: 0,
      mainTabs: [],
      subTabs: [],
      allSubTabs: [],
      urlButtons: [],
      activeSubTab: "",
      activeUrlButtons: [],
      currentDashboardUrl: "",
      showModal: false,
      sisenseFrame: "",
      filterArray: [],
      filterArrayGPH: [],
      sisenseStyle: [],
      sisenseFrames: [],
      isRendered: false,
      applyFiltersLater: false,
      filterApplied: false,
      showLoader: false,
      dbList: [],
      showDashModal: false,
      preLoadfoundationOverview: null,
      preLoadAutomatedOverview: null,
      preLoadInsightOverview: null,
      preLoadIntelligentOverview: null,
      showSisenseModal: false,
      selectedURL: "",
      isSisenseSubtab: false,
      //Prod fix
      showPowerBi: false,
      powerBiEmbedUrl: "",
      powerBiReportId: "",
      powerBiFilterObj: {},
      showPowerBiModal: false,
      powerBiEmbedUrlModal: "",
      powerBiReportIdModal: "",
      showPowerBiWithoutToken: false,
      powerBidirectUrl: "",
      showPowerBiWithoutTokenBtn: false,
      powerBidirectUrlBtn: "",
      //ends
      modalbreadCrum: "",
      showPopUpTableau: false,
      hideAnnouncementHolderDiv: true,
      allowAnnouncementAutoPopup: localStorage.getItem('announcementChecked') === 'true' ? true : false,
      filterText: "»",
      filterTextModal: "»",
      gphVersion: null,
      maximizeIOJDashboard: false,
      maximizeIOJPopup: false,
      filterArrayTenantId: [],
      dummyValue: 0, //dummy state value to trigger announement textarea refresh 
      showReconFilter: false,
      showHRManagerFilter: false,
      deploymentType: buttonFilters[0].data[0].value,
      outputVariance: buttonFilters[1].data[0].value,
      showMaskedUnmasked: false,
      clientType: [
        // {label:"Client ID", value:"tenantId"},
        { label: "Pseudo", value: "pseudo" }, { label: "Client", value: "client" }],
      selectedClientType: "pseudo",
      clientPseudoClientidList: [],
      clientNamesList: [],
      pseudoNamesList: [],
      tenantIdsList: [],
      seletedClientName: "all",
      selectedPseudoName: "all",
      selectedTenantId: "all",
      alertsDataB9: [],
      alertObject:{},
      selectedTab:"Alert",
      showAnnouncements: true,
      activeSubTabDashObj:{
        activeDahObj:null,
        activeWidgets:null,
        activeDivId:null
      },
      activeButtonDashObj:{
        activeDahObj:null,
        activeWidgets:null,
        activeDivId:null
      },
      activeDrillDashObj:{
        activeDahObj:null,
        activeWidgets:null,
        activeDivId:null
      },
      //SVM states
      showSVMPopUp:false,
      selectedYear: '',
      selectedProgram: '',
      startButton: true,
      isStart: true,
      isKnowMore: true,
      isInitialized: false,
      dashboardProgram: [],
      dashboardData: [],
      dashboardClientDetails: {},
      reportingYear: [],
      programFiscalYearRange: {
        min: 0,
        max: 0,
      },
      dbDimensionList: undefined,
      dimensionSubDimension: [],
      programID: '',
      dashboardProgramDetails: {},
      dashboardCommentary: {},
      selectedClient: {},
      reportingYr:2022,
      maximizeSVMDashboard:false,
      bankWgt :["66daf010173ed70033832d77","66daf010173ed70033832d7f", "66daf010173ed70033832d80"],
      parentFilters:[]
    }

  }

  hideModal = () => {
    this.setState({
      showPopUp: false,
      alertObject:{},
      tabParentId:0
    })
  }
  hideSVMModal = () => {
    this.setState({
      showSVMPopUp: false,
      alertObject:{},
      tabParentId:0
    })
  }
  handleMaxMin = () => {
    this.setState({ maximizeIOJDashboard: !this.state.maximizeIOJDashboard },()=>{
      if(this.props.currentApp && this.props.currentApp.dashboards){
        if(this.state.showDashModal){
        if(this.state.activeDrillDashObj.activeDahObj){
          this.loadWgt(this.state.activeDrillDashObj.activeDahObj, this.state.activeDrillDashObj.activeWidgets, this.state.activeDrillDashObj.activeDivId);
        }
        }else if(this.state.showSisenseModal){
        if(this.state.activeButtonDashObj.activeDahObj){
          this.loadWgt(this.state.activeButtonDashObj.activeDahObj, this.state.activeButtonDashObj.activeWidgets, this.state.activeButtonDashObj.activeDivId);
        }
        }else if(this.state.isSisenseSubtab){
        if(this.state.activeSubTabDashObj.activeDahObj){
          this.loadWgt(this.state.activeSubTabDashObj.activeDahObj, this.state.activeSubTabDashObj.activeWidgets, this.state.activeSubTabDashObj.activeDivId);
        }
        }
      }
    })
  }
  handleMaxMinPopup = () => {
    this.setState({ maximizeIOJPopup: !this.state.maximizeIOJPopup },()=>{
      if(this.props.currentApp && this.props.currentApp.dashboards){
        if(this.state.showDashModal){
          if(this.state.activeDrillDashObj.activeDahObj){
          this.loadWgt(this.state.activeDrillDashObj.activeDahObj, this.state.activeDrillDashObj.activeWidgets, this.state.activeDrillDashObj.activeDivId);
          }
        }else if(this.state.showSisenseModal){
          if(this.state.activeButtonDashObj.activeDahObj){
          this.loadWgt(this.state.activeButtonDashObj.activeDahObj, this.state.activeButtonDashObj.activeWidgets, this.state.activeButtonDashObj.activeDivId);
          }
        }else if(this.state.isSisenseSubtab){
          if(this.state.activeSubTabDashObj.activeDahObj){
          this.loadWgt(this.state.activeSubTabDashObj.activeDahObj, this.state.activeSubTabDashObj.activeWidgets, this.state.activeSubTabDashObj.activeDivId);
          }
        }
      }
    })
  }
  handleMaxMinSVM =()=>{
    this.setState({ maximizeSVMDashboard: !this.state.maximizeSVMDashboard })
  }
  handleNewTab = (url) => {
    let win;
    //  let win = window.open(url, '_blank','top=50,left=500,location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no,width=4000,height=900');
    if(window.screen.height >= '1080' && window.screen.width >= '5760'){
      win = window.open(url, '_blank', 'top=00,left=1860,location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no,width=1900,height=1080');
    }else{
      win = window.open(url, '_blank', 'top=00,left=1240,location=no,status=no,menubar=no,toolbar=no,resizable=no,scrollbars=no,width=1280,height=985');
    }
    win.focus();
  }
  hideModalTableau = () => {
    this.setState({
      showPopUpTableau: false,
      maximizeIOJPopup: false
    })
  }
  handleSisenseClose = () => this.setState({
    showSisenseModal: false, showReconFilter: false, showHRManagerFilter: false, showMaskedUnmasked: false,
    selectedClientType: "pseudo",
    seletedClientName: "all",
    selectedPseudoName: "all",
    selectedTenantId: "all", maximizeIOJPopup: false
  });
  showConfirmAlert = (url) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
          <div className='custom-ui'>
            <div className="card-appstore">
              <h4>{i18n.t("This app opens in new window")}</h4>
              <div >{i18n.t("Do you want to continue ?")}</div>
              <div className="card-appstore-btns" style={{ position: 'relative', left: '70%' }}>
                <button type="button" className="btn-remove" style={{ width: '4rem', marginRight: '5px' }} onClick={() => {
                  this.handleNewTab(url);
                  onClose();
                }}>{i18n.t("Ok")}</button>
                <button type="button" className="btn-remove" style={{ width: '4rem' }} onClick={onClose}>{i18n.t("Cancel")}</button>

              </div>
            </div>
          </div>
        );
      }
    });
  }

  handleNotificationChange = (value) => {
    this.setState({ allowAnnouncementAutoPopup: value ? true : false })

    let allowAnnouncement = this.props.userDetails.id;
    this.props.showPopUp(allowAnnouncement, !value);
    localStorage.setItem('announcementChecked', value.toString());
  }
									  
								   
   

  handleAnnouncementBtnClick = () => {
    this.setState({
      hideAnnouncementHolderDiv: false,
      hideFilterHolderDiv: !this.state.hideFilterHolderDiv,
      selectedTab:this.state.alertsDataB9.length>0?"Alert":"Announcement"
    })
    this.props.getAnnouncements();
    // let tenantId = this.props.selectedClient && this.props.selectedClient.tenantID ? this.props.selectedClient.tenantID : 0
    // this.getAlertData(tenantId);
  }

  handleViewMoreAnnouncementClick = () => {
    this.props.announcementPopUp(true)
    this.setState({ hideAnnouncementHolderDiv: true, showAnnouncements: false })
  }
  handleCloseAnnouncementBtnClick = () => {
    this.setState({
      hideAnnouncementHolderDiv: true, showAnnouncements: false
    })
  }

  toggleModalAppStore = () => {
    this.setState({
      showPopUpAppStore: true
    })
  }
  hideModalAppStore = () => {
    this.setState({
      showPopUpAppStore: false
    })
  }
  toggleModalSVM = () => {
    this.setState({
      showSVMPopUp: true,
      isKnowMore:true
    })
  }
  componentDidMount() {
    if((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26)) ||
          (this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 30)) ||
          (this.props.userDetails.dashboardRole == ADMIN)){
        let tenantId = this.props.selectedClient && this.props.selectedClient.tenantID ? this.props.selectedClient.tenantID : 0
        this.getAlertData(tenantId);
      }	
     if (this.props.userDetails.userPreference.allowAnnouncementAutoPopup == false || (this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26) === false && this.props.userDetails.dashboardRole != ADMIN)) {
      this.setState({ showAnnouncements: false });
      }
      else {
        this.setState({ showAnnouncements: this.props.announcements.length !=0?true:false, selectedTab:this.props.announcements.length !=0?"Announcement":"" });
      }
	
    // setTimeout(() => { this.setState({ dummyValue: this.state.dummyValue + 1 }); }, 1500)
  }
  componentDidUpdate() {
    // setTimeout(() => { this.setState({ dummyValue: this.state.dummyValue + 1 }); }, 1500)
  }
  componentWillMount() {
    this.getLanguageList();
    let gphVersion = this.props.config[0].gphVersion ? this.props.config[0].gphVersion : null;
    let filteredConfig = [];

    let clients;
    let tenantIds;
    let selectedClient={};
    //  svm client object
  // if(this.props.app.filterObject.tenantId.length == 0 && this.props.app.clientOptions.length>0){
  //   // if(this.props.app.clientOptions.some(item=> item.isDemo == true)){
  //     selectedClient = {
  //       value: this.props.app.clientOptions[0].globalSVMClientId,
  //       // text: this.props.app.clientOptions[0].tenantName,
  //       // label: this.props.app.clientOptions[0].tenantName,
  //       text: 'Force 10',
  //       label: 'Force 10',
  //     }
  //   // }else{
  //   //   this.setState({activeTab:1})
  //   // }
  //       }else 
        if(this.props.app.clientOptions.length>0){
            // if((this.props.app.clientOptions.find(item=> item.tenantID == this.props.app.filterObject.tenantId[0])).svmClientID != 0){
              selectedClient = {
                value: this.props.app.clientOptions[0].globalSVMClientId,
                // text: this.props.app.clientOptions[0].tenantName,
                // label: this.props.app.clientOptions[0].tenantName,
                text: 'Force 10',
                label: 'Force 10',
              }
            // }else{
            // this.setState({showSVMPopUp:false})
            // }
        }
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.clients];
      tenantIds = [...this.props.app.filterObject.tenantId]
    } else {
      let tenants = [];
      let ids = [];
      this.props.userDetails.tenant.map((item) => {
        // tenants.push(item.pseudoName);
        tenants.push(item.tenantName);
        ids.push(item.tenantID);
      });
      clients = [...tenants];
      tenantIds = [...ids]
    }
    if (clients.length > 0 && gphVersion == null) {
      gphVersion = "iwgph"
    }
    switch (gphVersion) {
      case null: {
        this.props.config.map((item) => {
          if (item.url) {
            let value = allGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
      }
        break;
      case "iwgph": {
        this.props.config.map((item) => {
          if (item.url) {
            let value = iwGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
      }
        break;
      case "gph4.0": {
        this.props.config.map((item) => {
          if (item.url) {
            let value = gph4Exclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.url.includes("61e6ac4169f6ec003698d2fb") && !value) {
              item.url = item.url.replace("61e6ac4169f6ec003698d2fb", "5ecbbd7792aefc1cf806be4f");
              let oldElementId = document.getElementById("61e6ac4169f6ec003698d2fb");
              if (oldElementId) {
                oldElementId.id = "5ecbbd7792aefc1cf806be4f";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
      }
        break;
      default:
        break;
    }
    let mainTabs = this.props.config.filter((item) => item.type == "Tab");
    let subTabs = filteredConfig.filter((item) => item.type == "SubTab");
    let urlButtons = filteredConfig.filter((item) => item.type == "Button");

    //sisense dashboards
    let sisenseStyle = [];
    subTabs.forEach((db, i) => {
      if (db.url) {
        let isSisenseUrl = true;
        isSisenseUrl = db.visualizationType == "sisense" || db.visualizationType == null;
        if (isSisenseUrl) {
          let dashboardId = db.url.split("/");
          let dashId = dashboardId[dashboardId.length - 1];
          sisenseStyle.push({ id: dashId, url: db.url, style: { display: "none" } });
        } else {
          sisenseStyle.push({ id: db.id, url: db.url, style: { display: "none" } });
        }
      }
    });
    let clientsFilterObj;
    let tenantIdFilterObj;
    let gphFilterArray;
    if (clients.length > 0) {
      clientsFilterObj = {
        explicit: true,
        multiSelection: true,
        members: clients.map((a) => a.toUpperCase()),
      }
      tenantIdFilterObj = {
        explicit: true,
        multiSelection: true,
        members: this.props.app.demoClient ? [window.demoClient.id] : tenantIds.map((a) => a), //hardcoded
      }
      gphFilterArray = [
        { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { "all": true } } },
        { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { "all": true } } },
        { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { "all": true } } }
      ]
    } else {
      clientsFilterObj = {
        all: true,
      }
      tenantIdFilterObj = {
        all: true,
      }
      gphFilterArray = [
        { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { members: ["BPO"] } } },
        { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { members: ["Financial Services"] } } },
        { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { members: ["Communications, Media and Technology"] } } }
      ]
    }
    this.setState({
      selectedClient,
      mainTabs,
      subTabs,
      allSubTabs: subTabs,
      urlButtons,
      sisenseStyle,
      gphVersion: gphVersion,
      filterArray: [
        {

          levels: [
            {
              dim: "[Client.Type]",
              datatype: "text",
              title: "Type",
              filter: {
                explicit: true,
                userMultiSelect: false,
                multiSelection: false,
                members: ["Demo"],
              },
            },
            {
              dim: "[Client.Client]",
              datatype: "text",
              title: "Client",
              filter: clientsFilterObj,
            },
          ],
        },
      ],
      filterArrayGPH: gphFilterArray,
      filterArrayTenantId: [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": tenantIdFilterObj } }]
    });
  }
  onChangeLanguage = (e) => {
    let filtered = [...this.state.languageList]

    let searchedText = e.target.value.toLowerCase().trim()
    this.setState({
      languageListFiltered: filtered.filter(x => x.languageDescription.toLowerCase().includes(searchedText)),
      searchedLanguageText: e.target.value
    })
  }
  onSelectLanguage = (language) => {
    this.setState({
      hideLanguageDiv: true,
      openedDropdown: "",
      languageListFiltered: this.state.languageList
    })
    this.props.setUserPreferedLanguage(language.languageCode, language.languageDescription);
    let selectedLanguage = language.languageCode
    let body = {
      Id: this.props.userDetails.id,
      Language: language.languageCode,
      ShowGlobe: true,
      Theme: "blue",
      Application: "CommandCenter"
    }
    api.post(`userpreference`, body)
      .then((res) => {
        if (res === 'Success') {
          this.getTranslations(selectedLanguage)
        }
      })
      .catch((error) => {
        //Check if it is a duplicate entry


      });

  }
  getAlertData = (tenantId) => {
    // tenantId = this.props.selectedClient && this.props.selectedClient.tenantID ? this.props.selectedClient.tenantID : 0 ;
    // console.log(tenantId,"tenant");
    api.get(`notificationalerts/`+ tenantId).then((res) => {
     if (res.length > 0) {
       this.setState({ alertsDataB9: res });
     }
     else {
       this.setState({ alertsDataB9: []});
     }
   });
 }
 getDismissedData = (rowId) =>{
  let tenantId = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0 ;
  api.get(`dismissalerts/${rowId}/${tenantId}/`).then((res) => {
    if (res.length > 0) {
     this.setState({alertsDataB9: res});
    }
    else {
      this.setState({alertsDataB9: []});
    }
  });
 }
 getDismissedAllData = () =>{
  let tenantid = this.state.selectedClient && this.state.selectedClient.tenantID ? this.state.selectedClient.tenantID : 0 ;
  api.get(`dismissallalerts/`+ tenantid).then((res) => {
    if (res.length > 0) {
      this.setState({ alertsDataB9: res});
     }
     else {
      if(this.props.announcements.length != 0){
        this.setState({ alertsDataB9: [], selectedTab:"Announcement"});
      }else{
        this.setState({ alertsDataB9: [], 
                        selectedTab:"",
                        hideAnnouncementHolderDiv: true, 
                        showAnnouncements: false});
      }
     }
   });
 }
  getTranslations = (languageCode) => {
    api
      .get(`translatedtext/${languageCode}`)
      .then((res) => {
        let literals = {};
        literals[languageCode] = { translation: res };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: languageCode,
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
        this.props.setLanguageTranslations(res);
      })
      .catch((err) => {
        let literals = {};
        literals["en"] = { translation: {} };
        i18n.use(initReactI18next).init({
          resources: literals,
          lng: "en",
          fallbackLng: "en",

          interpolation: {
            escapeValue: false,
          },
        });
      })
  }
  getLanguageList = () => {
    api
      .get(`languages`)
      .then((res) => {
        const langList = res;
        this.setState({
          languageList: langList,
          languageListFiltered: langList
        })
      })
      .catch((error) => {
        //Check if it is a duplicate entry

      });
  }
  handletoggleBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: false,
      hideAnnouncementHolderDiv: true
    })

  }
  handleCloseProfileBtnClick = () => {
    this.setState({
      hideProfileHolderDiv: true,
      openedDropdown: ""
    })
  }
  applyDbFiltersFixed = (db, dashId, payload) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };
    let clients;
    if (this.props.app.filterObject.tenantId.length > 0) {
      clients = [...this.props.app.filterObject.tenantId];
    } else {
      let tenants = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.tenantID);
      });
      clients = [...tenants];
    }
    let updatedPayload = ""
    let selectedClientType = "pseudo";
    switch (this.state.selectedClientType) {
      // case "tenantId":
      //   selectedClientType = "Client";
      //   break;
      case "pseudo":
        selectedClientType = "Pseudo";
        break;
      case "client":
        selectedClientType = "Client";
        break;
    }
    if (clients.length > 0 || (this.state.showMaskedUnmasked && this.state.selectedTenantId != "all")) {
      let client_name = (this.state.selectedTenantId != "all" && this.state.showMaskedUnmasked)
        ? this.state.selectedTenantId
        : clients.map((a) => a)[0];
      if (this.state.showReconFilter) {
        updatedPayload = payload.replace("_CLIENT_", client_name).replace("_FILTER_", this.state.deploymentType).replace("_ClientSelection_", selectedClientType)
      } else if (this.state.showHRManagerFilter) {
        updatedPayload = payload.replace("_CLIENT_", client_name).replace("_FILTER_", this.state.outputVariance).replace("_ClientSelection_", selectedClientType)
      } else {
        updatedPayload = payload.replace("_CLIENT_", client_name).replace("_ClientSelection_", selectedClientType)
      }
    } else {
      if (this.state.showReconFilter) {
        updatedPayload = payload.replace(`"members":["_CLIENT_"]`, `"all": true`).replace("_FILTER_", this.state.deploymentType).replace("_ClientSelection_", selectedClientType)
      } else if (this.state.showHRManagerFilter) {
        updatedPayload = payload.replace(`"members":["_CLIENT_"]`, `"all": true`).replace("_FILTER_", this.state.outputVariance).replace("_ClientSelection_", selectedClientType)
      } else {
        updatedPayload = payload.replace(`"members":["_CLIENT_"]`, `"all": true`).replace("_ClientSelection_", selectedClientType)
      }
    }

    db.$$model.filters.update(JSON.parse(updatedPayload), filterOptions);

  };
  applyDbFilters = (db, dashId) => {
    const filterOptions = {
      save: true,
      refresh: true,
      unionIfSameDimensionAndSameType: false,
    };
    let filterArrayLanguage = [{ "jaql": { "dim": "[Translation.Language]", "datatype": "text", "title": "Language", "filter": { "members": [this.props.app.language] } } }]

    let filters = [...this.state.filterArray];
    let isTenantIdFilter = tenantIdDashboards.some(element => {
      return dashId == element.dashboardId
    })
    let isDemoTypeDashboard = demoTypeDashboards.some(element=>{
      return dashId == element.dashboardId
   })
   let isDrillToDash = drillToDashWithParentFilter.some(element => {
      return dashId == element.dashboardId
    })
   if(isDemoTypeDashboard){
    var demoFilterJaql = [
      {
        levels: [
          {
            dim: "[Client.Type]",
            datatype: "text",
            title: "Type",
            filter: {
              explicit: true,
              userMultiSelect: false,
              multiSelection: false,
              members: ["Demo"],
            },
          },
          {
            title: "Client",
            dim: "[Client.Client]",
            datatype: "text",
            filter: {
              all: true,
            },
          },
        ],
      },
    ];
   }
   if(isDrillToDash){
      const drillToPayload = [...this.state.parentFilters];
    db.$$model.filters.update(drillToPayload, filterOptions);
    }else{
    if (isTenantIdFilter) {
      const tenantPayload = [...this.state.filterArrayTenantId];
      db.$$model.filters.update(tenantPayload, filterOptions);
    } else {
      if (dashId == '5ecbbd7792aefc1cf806be4f') {
        let payload = this.state.filterArray.concat(this.state.filterArrayGPH);
        db.$$model.filters.update(payload, filterOptions);
      // } else if (dashId == '5f17f33100e5352f388da331') {
      //   let payload = this.state.filterArray.concat(filterArrayLanguage); // Removed this after creation of process mining dashboard
      //   db.$$model.filters.update(payload, filterOptions);
      } else if(isDemoTypeDashboard){
        db.$$model.filters.update(demoFilterJaql[0], filterOptions);
      } else {
        db.$$model.filters.update(filters[0], filterOptions);
      }
    }
    }
  };
  reMountComponents = (props) => {
    let gphVersion = props.config[0].gphVersion ? props.config[0].gphVersion : null;
    let filteredConfig = [];
    let allsubtabsArray = this.state.allSubTabs;
    let clients;
    if (props.app.filterObject.tenant.length > 0) {
      clients = [...props.app.filterObject.tenant];
    } else {
      let tenants = [];
      props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
      });
      clients = [...tenants];
    }
    if (clients.length > 0 && gphVersion == null) {
      gphVersion = "iwgph"
    }
    switch (gphVersion) {
      case null: {
        allsubtabsArray = [];
        props.config.map((item) => {
          if (item.url) {
            let value = allGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.type == "SubTab" && item.url.includes("5ecbbd7792aefc1cf806be4f") && !value) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
              let oldElementId = document.getElementById("5ecbbd7792aefc1cf806be4f");
              if (oldElementId) {
                oldElementId.id = "61e6ac4169f6ec003698d2fb";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
        //replace url in allsubtabs
        this.state.allSubTabs.map((item) => {
          if (item.url) {
            if (item.url.includes("5ecbbd7792aefc1cf806be4f")) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
            }
            allsubtabsArray.push(item);
          }
        })
      }
        break;
      case "iwgph": {
        allsubtabsArray = [];
        props.config.map((item) => {
          if (item.url) {
            let value = iwGphExclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.type == "SubTab" && item.url.includes("5ecbbd7792aefc1cf806be4f") && !value) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
              let oldElementId = document.getElementById("5ecbbd7792aefc1cf806be4f");
              if (oldElementId) {
                oldElementId.id = "61e6ac4169f6ec003698d2fb";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
        //replace url in allsubtabs
        this.state.allSubTabs.map((item) => {
          if (item.url) {
            if (item.url.includes("5ecbbd7792aefc1cf806be4f")) {
              item.url = item.url.replace("5ecbbd7792aefc1cf806be4f", "61e6ac4169f6ec003698d2fb");
            }
            allsubtabsArray.push(item);
          }
        })
      }
        break;
      case "gph4.0": {
        allsubtabsArray = [];
        props.config.map((item) => {
          if (item.url) {
            let value = gph4Exclude.some(element => {
              return item.url.includes(element.dashboardId);
            })
            if (item.url.includes("61e6ac4169f6ec003698d2fb") && !value) {
              item.url = item.url.replace("61e6ac4169f6ec003698d2fb", "5ecbbd7792aefc1cf806be4f");
              let oldElementId = document.getElementById("61e6ac4169f6ec003698d2fb");
              if (oldElementId) {
                oldElementId.id = "5ecbbd7792aefc1cf806be4f";
              }
            }
            if (!value) {
              filteredConfig.push(item);
            }
          }
        })
        //replace url in allsubtabs
        this.state.allSubTabs.map((item) => {
          if (item.url) {
            if (item.url.includes("61e6ac4169f6ec003698d2fb")) {
              item.url = item.url.replace("61e6ac4169f6ec003698d2fb", "5ecbbd7792aefc1cf806be4f");
            }
            allsubtabsArray.push(item);
          }
        })
      }
        break;
      default:
        break;
    }
    let mainTabs = props.config.filter((item) => item.type == "Tab");
    let subTabs = filteredConfig.filter((item) => item.type == "SubTab");
    let urlButtons = filteredConfig.filter((item) => item.type == "Button");
    // let clients = [...props.app.filterObject.tenantId];

    //sisense dashboards
    let sisenseStyle = [];
    subTabs.forEach((db, i) => {
      if (db.url) {
        let isSisenseUrl = true;
        isSisenseUrl = db.visualizationType == "sisense" || db.visualizationType == null;
        if (isSisenseUrl) {
          let dashboardId = db.url.split("/");
          let dashId = dashboardId[dashboardId.length - 1];
          sisenseStyle.push({ id: dashId, url: db.url, style: { display: "none" } });
        } else {
          sisenseStyle.push({ id: db.id, url: db.url, style: { display: "none" } });
        }
      }
    });
    //check if IOJ subtabs are open
    let tabParentId=this.state.tabParentId;
    let defaultSubTab=0;
  if(this.state.tabParentId == 0){
    tabParentId = (Object.keys(this.state.alertObject).length != 0) ? this.state.alertObject.configParentId : this.state.tabParentId;
    defaultSubTab = (Object.keys(this.state.alertObject).length != 0) ? this.state.alertObject.configId : 0;
  }
  
  const mergedArray = [...allsubtabsArray, ...subTabs];
    const map = {};
    for (const element of mergedArray) {
      map[element.id] = element;
    }
    const newArray = Object.values(map);

    this.setState({
      mainTabs,
      subTabs,
      allSubTabs: [...newArray],
      urlButtons,
      sisenseStyle,
      gphVersion: gphVersion,
      tabParentId

    }, () => this.handleTabChange(tabParentId, defaultSubTab));
  };
  componentWillReceiveProps(nextProps) {
    if ((this.props.config !== nextProps.config) || (this.props.app.language != nextProps.app.language)) {
      this.reMountComponents(nextProps);
    }
    if (
      (this.props.app.filterObject.tenant !== nextProps.app.filterObject.tenant) || 
      (this.props.app.clientOptions != nextProps.app.clientOptions)
    ) {
      //   //svm client object
      let selectedClient={};
      // if(nextProps.app.filterObject.tenantId.length == 0 && nextProps.app.clientOptions.length>0){
      //   // if(nextProps.app.clientOptions.some(item=> item.isDemo == true)){
      //     selectedClient = {
      //       value: nextProps.app.clientOptions[0].globalSVMClientId,
      //       text: nextProps.app.clientOptions[0].tenantName,
      //       label: nextProps.app.clientOptions[0].tenantName,
      //       text: 'Force 10',
      //       label: 'Force 10',
      //     }
      //   // }else{
      //   //   this.setState({activeTab:1})
      //   // }
      // }else
       if(nextProps.app.clientOptions.length>0){
        // if((nextProps.app.clientOptions.find(item=> item.tenantID == nextProps.app.filterObject.tenantId[0])).svmClientID != 0){
          selectedClient = {
            value: nextProps.app.clientOptions[0].globalSVMClientId,
            // text: nextProps.app.clientOptions[0].tenantName,
            // label: nextProps.app.clientOptions[0].tenantName,
            text: 'Force 10',
            label: 'Force 10',
          }
        // }else{
        //   this.setState({showSVMPopUp:false})
        // }
      }

      let tenantId = nextProps.app.filterObject.tenantId.length>0 ? nextProps.app.filterObject.tenantId[0] : 0
      this.getAlertData(tenantId);
      let filterArray = [];
      let gphFilterArray = [];
      let filterArrayTenantId = [];
      if (
        nextProps.app.filterObject.tenant.length == 0 &&
        this.props.userDetails.tenant.length == 0
      ) {
        filterArray = [
          {
            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Demo"],
                },
              },
              {
                title: "Client",
                dim: "[Client.Client]",
                datatype: "text",
                filter: {
                  all: true,
                },
              },
            ],
          },
        ];
        gphFilterArray = [
          { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { members: ["BPO"] } } },
          { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { members: ["Financial Services"] } } },
          { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { members: ["Communications, Media and Technology"] } } }
        ]
        filterArrayTenantId = [{ "jaql": { "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": { all: true } } }]
      } else if (
        nextProps.app.filterObject.tenant.length == 0 &&
        this.props.userDetails.tenant.length != 0
      ) {
        let tenants = [];
        this.props.userDetails.tenant.map((item) => {
          // tenants.push(item.pseudoName);
          tenants.push(item.tenantName);
        });
        let tenantIds = [];
        this.props.userDetails.tenant.map((item) => {
          tenantIds.push(item.tenantID);
        });
        filterArray = [
          {

            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Demo"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {
                  explicit: true,
                  multiSelection: true,
                  members: tenants.map((a) => a.toUpperCase()),
                },
              },
            ],
          },
        ];
        gphFilterArray = [
          { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { "all": true } } }
        ];
        filterArrayTenantId = [{
          "jaql": {
            "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
              explicit: true,
              multiSelection: true,
              members: this.props.app.demoClient ? [window.demoClient.id] : tenantIds.map((a) => a), //hardcoded 
            }
          }
        }]
      } else {
        filterArray = [
          {

            levels: [
              {
                dim: "[Client.Type]",
                datatype: "text",
                title: "Type",
                filter: {
                  explicit: true,
                  userMultiSelect: false,
                  multiSelection: false,
                  members: ["Demo"],
                },
              },
              {
                dim: "[Client.Client]",
                datatype: "text",
                title: "Client",
                filter: {
                  explicit: true,
                  multiSelection: true,
                  members:
                    nextProps.app.clients.map((a) =>
                      a.toUpperCase()
                    ),

                },
              },
            ],
          },
        ];
        gphFilterArray = [
          { jaql: { dim: "[Hierarchy.Organization]", datatype: "text", title: "Organization", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Operating Group]", datatype: "text", title: "Operating Group", filter: { "all": true } } },
          { jaql: { dim: "[Hierarchy.Industry]", datatype: "text", title: "Industry", filter: { "all": true } } }
        ];
        filterArrayTenantId = [{
          "jaql": {
            "dim": "[Client.Tenant Identifier]", "datatype": "numeric", "title": "Tenant Identifier", "filter": {
              explicit: true,
              multiSelection: true,
              members: nextProps.app.demoClient ? [window.demoClient.id]
                : nextProps.app.filterObject.tenantId.map((a) =>
                  a
                ), //hardcoded

            },
          }
        }]

      }
      if((this.state.selectedClient.value && selectedClient.value) && (this.state.selectedClient.value == selectedClient.value)){
        this.setState({ filterArray, filterArrayGPH: gphFilterArray, filterArrayTenantId,});
      }else{
      this.clearDashboard();
      this.setState({selectedClient, filterArray, filterArrayGPH: gphFilterArray, filterArrayTenantId,
      selectedYear: '',
      selectedProgram: '',
      startButton: true,
      isStart: true,
      isKnowMore: true,
      isInitialized: false,
      dashboardProgram: [],
      dashboardData: [],
      dashboardClientDetails: {},
      reportingYear: [],
      programFiscalYearRange: {
        min: 0,
        max: 0,
      },
      dbDimensionList: undefined,
      dimensionSubDimension: [],
      programID: '',
      dashboardProgramDetails: {},
      dashboardCommentary: {},
    });
  }
      this.handleClick('id', this.state.selectedURL, false, "sisense");
    }
  }
  handleClick = (id, url, openInNewWindow, visualizationType) => {
    this.setState({
      showLoader: false
    })
    let isSisenseUrl = true;
    let PowerBiReportId;
    let isPowerBiUrl = true;
    if (url) {
      isSisenseUrl = visualizationType == "sisense" || visualizationType == null;
      isPowerBiUrl = visualizationType == "powerbi"; //change_report
      //powerBI api
      if (isPowerBiUrl && this.props.userDetails.isPowerBIEnabled && !openInNewWindow) {
        let isPowerBiService = false;
        isPowerBiService = url.includes('app.powerbi.com');
        let clients = [];
        if (isPowerBiService) {
          PowerBiReportId = url.substring(
            url.lastIndexOf("reportId") + 9,
            url.lastIndexOf("reportId") + 45)
          fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
            method: "GET",
            headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
          }).then(response => response.json())
            .then(
              (res) => {
                clients = [];
                let powerFilter = {};
                if (this.props.app.filterObject.tenant.length > 0) {
                  clients = [...this.props.app.filterObject.tenant];
                } else {
                  let tenants = [];
                  this.props.userDetails.tenant.map((item) => {
                    tenants.push(item.pseudoName);
                  });
                  clients = [...tenants];
                }
                if (clients.length > 0) {
                  powerFilter = {
                    $schema: "http://powerbi.com/product/schema#basic",
                    target: {
                      table: "Tenant",
                      column: "PseudoName"
                    },
                    operator: "Is",
                    values: clients.map((a) => a.toUpperCase())
                  }
                }

                this.setState({ showPowerBi: true, powerBiReportId: PowerBiReportId, powerBiEmbedUrl: res.embedUrl, powerBiFilterObj: powerFilter })
              },
              (error) => {
                console.log("PowerBi fetcherror", error);
              }
            )
        } else {
          let urlWithFilter = url;
          if (this.props.app.filterObject.tenant.length > 0) {
            clients = [...this.props.app.filterObject.tenant];
          } else {
            let tenants = [];
            this.props.userDetails.tenant.map((item) => {
              tenants.push(item.pseudoName);
            });
            clients = [...tenants];
          }
          if (clients.length > 0) {
            let addClientFilter = this.props.config.filter((y) => y.id == id)[0]
              .addClientFilter;
            let filterParams = this.props.config.filter((y) => y.id == id)[0]
              .filterParams;
            let replaceString = this.props.config.filter((y) => y.id == id)[0]
              .replaceString;
            clients = clients.join();
            let n = url.indexOf("?");
            if (addClientFilter == true) {
              urlWithFilter = this.ReplaceUrlString(
                url,
                filterParams,
                replaceString,
                clients,
                n
              );
            }
          }
          this.setState({ showPowerBiWithoutToken: true, powerBidirectUrl: urlWithFilter })

        }

      } else {
        this.setState({ selectedURL: url });
        let dashboardId = url.split("/");
        let dashId = dashboardId[dashboardId.length - 1];
        let sisenseStyle = [];
        this.state.subTabs.forEach((db, i) => {
          if (db.url) {
            let isSisence = false;
            isSisence = visualizationType == "sisense" || visualizationType == null;
            if (isSisence) {
              // this.setState({ showDashFilt: false, filterText: "Â»" })
              let dbId = db.url.split("/");
              let ID = dbId[dbId.length - 1];
              sisenseStyle.push({
                id: ID,
                url: db.url,
                style: ID === dashId ? { display: "block" } : { display: "none" },
              });
            } else {
              let tempUrl = db.url;
              let filterParams = this.props.config.filter((y) => y.id == db.id)[0]
                .filterParams;
              let replaceString = this.props.config.filter((y) => y.id == db.id)[0]
                .replaceString;
              let urlType = this.props.config.filter((y) => y.id == db.id)[0].urlType;

              if (this.props.app.filterObject.tenant.length > 0 && db.addClientFilter == true) {
                let tenants = this.props.app.filterObject.tenant;
                tenants = tenants.join();
                let n = db.url.indexOf("?");
                if (urlType == 'APPEND') {
                  tempUrl = this.ReplaceUrlString(
                    db.url,
                    null,
                    replaceString,
                    tenants,
                    n
                  );
                } else if (urlType == 'REPLACE' && filterParams) {
                  tempUrl = this.ReplaceUrlString(
                    db.url,
                    filterParams,
                    replaceString,
                    tenants,
                    n
                  );
                }
              }
              sisenseStyle.push({
                id: db.id,
                url: tempUrl,
                style: db.id === id ? { display: "block" } : { display: "none" },
              });
              if (db.isOpenInNewWindow && db.id === id) {
                this.showConfirmAlert(db.url);
              }
            }

          }
        });
        this.setState({ sisenseStyle, filterApplied: true });
        setTimeout(() => {
          this.setState({ filterApplied: false });
        }, 2000);
        if (this.props.currentApp && this.props.currentApp.dashboards && isSisenseUrl && this.props.isConnected) {
          this.loadDash(dashId, url);

        }
      }
    }

  };
  toggleDropdown = (value) => {
    this.setState({
      hideLanguageDiv: false,
      searchedLanguageText: ""
    })
    if (!this.state.loading) {
      if (this.state.openedDropdown == value) {
        this.setState({ openedDropdown: "", searchedLanguageText: "" });
      } else {
        switch (value) {
          case "Select Language": {
            //  this.getLanguageList();
            this.setState({ openedDropdown: "Select Language", languageListFiltered: this.state.languageList });
          }
            break;
          default:
            break;
        }
      }

    }
  };
  callUserEngagementAPI = (id) => {
    this.props.callUserEnagementSessionAPI('IOJ', id, 0, true)
  }

  setSubTab = (element) => {
    if (element) {
      //Clear previous sisense frame
      let activeUrlButtons = [];
      this.state.urlButtons
        .filter((item) => item.parentID == element.id)
        .sort((a, b) => a - b)
        .map((btn) => {
          activeUrlButtons.push(btn);
        });

      this.setState(
        { activeUrlButtons, activeSubTab: element.displayName, showPowerBi: false, showPowerBiWithoutToken: false },
        () => {
          this.callUserEngagementAPI(element.id)
          let isSisenseUrl = false;
          let isPowerBiUrl = false;
          if (element.url) {
            isSisenseUrl = element.visualizationType == "sisense" || element.visualizationType == null;
            isPowerBiUrl = element.visualizationType == "powerbi"; //change_report
            if (isSisenseUrl) {
              this.setState({ isSisenseSubtab: true });
            } else {
              this.setState({ isSisenseSubtab: false });
            }
          }
          // if (ENVIRONMENT !== 'local' || !isSisenseUrl || !isPowerBiUrl) {
          if (ENVIRONMENT !== 'local' || !isSisenseUrl) {
            this.handleClick(element.id, element.url, element.isOpenInNewWindow, element.visualizationType);  //Uncomment this before building - LOCAL
          }
        }
      );
    }
  };
     ReplaceUrlString = (url, filterParams, replaceString, tenants, n) => {
    if (n > -1) {
      return url + "&" + (filterParams ? filterParams.replace(replaceString, tenants) : (replaceString + "=" + tenants));
    } else {
      return url + "?" + (filterParams ? filterParams.replace(replaceString, tenants) : (replaceString + "=" + tenants));
    }
  };

  AppendUrlString = (url, replaceString, tenants, n) => {
    if (n > -1) return url + "&" + replaceString + "=" + tenants;
    else return url + "?" + replaceString + "=" + tenants;
  };
  handleUrlButton = (btn) => {
    this.setState({
      showLoader: false
    })
    let currentUrl = "";
    let isSisenseUrl = false;
    let isPowerBiUrl = false;
    let PowerBiReportId = ""
    let clientNamesList = [];
    let pseudoNamesList = [];
    let tenantIdsList = [];
    if (btn.iandIApplicationClientDetails.length > 0) {
      //add All client
      clientNamesList = [{ label: "All", value: "all" }];
      pseudoNamesList = [{ label: "All", value: "all" }];
      tenantIdsList = [{ label: "All", value: "all" }];
      btn.iandIApplicationClientDetails.map(item => {
        clientNamesList.push({ label: item.client, value: item.client });
        pseudoNamesList.push({ label: item.pseudoName, value: item.pseudoName });
        tenantIdsList.push({ label: item.tenantIdentifier, value: item.tenantIdentifier });
      })
    }
    this.setState({ clientPseudoClientidList: btn.iandIApplicationClientDetails, clientNamesList, pseudoNamesList, tenantIdsList })
    if (btn.url) {
      isSisenseUrl = btn.visualizationType == "sisense" || btn.visualizationType == null;
      isPowerBiUrl = btn.visualizationType == "powerbi";
    }
    if (isPowerBiUrl && this.props.userDetails.isPowerBIEnabled && !btn.isOpenInNewWindow) {
      let isPowerBiService = false;
      isPowerBiService = btn.url.includes('app.powerbi.com');
      let clients = [];
      if (isPowerBiService) {
        PowerBiReportId = btn.url.substring(
          btn.url.lastIndexOf("reportId") + 9,
          btn.url.lastIndexOf("reportId") + 45)
        fetch(`https://api.powerbi.com/v1.0/myorg/reports/${PowerBiReportId}`, {
          method: "GET",
          headers: { "Authorization": "Bearer " + localStorage.getItem("access_token_powerbi"), }
        }).then(response => response.json())
          .then(
            (res) => {
              let powerFilter = {};
              if (this.props.app.filterObject.tenant.length > 0) {
                clients = [...this.props.app.filterObject.tenant];
              } else {
                let tenants = [];
                this.props.userDetails.tenant.map((item) => {
                  tenants.push(item.pseudoName);
                });
                clients = [...tenants];
              }
              if (clients.length > 0) {
                powerFilter = {
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: "Tenant",
                    column: "PseudoName"
                  },
                  operator: "Is",
                  values: clients.map((a) => a.toUpperCase())
                }
              }

              this.setState({ showPowerBiModal: true, powerBiReportIdModal: PowerBiReportId, powerBiEmbedUrlModal: res.embedUrl, powerBiFilterObj: powerFilter })
            },
            (error) => {
              console.log("PowerBi fetcherror", error);
            }
          )
      } else {

        let urlWithFilter = btn.url;
        if (this.props.app.filterObject.tenant.length > 0) {
          clients = [...this.props.app.filterObject.tenant];
        } else {
          let tenants = [];
          this.props.userDetails.tenant.map((item) => {
            tenants.push(item.pseudoName);
          });
          clients = [...tenants];
        }
        if (clients.length > 0) {
          let filterParams = this.props.config.filter((y) => y.id == btn.id)[0]
            .filterParams;
          let replaceString = this.props.config.filter((y) => y.id == btn.id)[0]
            .replaceString;
          clients = clients.join();
          let n = btn.url.indexOf("?");
          if (btn.addClientFilter == true) {
            urlWithFilter = this.ReplaceUrlString(
              btn.url,
              filterParams,
              replaceString,
              clients,
              n
            );
          }

        }
        this.setState({ showPowerBiWithoutTokenBtn: true, powerBidirectUrlBtn: urlWithFilter })

      }
    } else {

      let urlType = this.props.config.filter((y) => y.id == btn.id)[0].urlType;
      let urlUpper = '';
      if (urlType) {
        urlUpper = urlType.toUpperCase();
      }
      let addClientFilter = this.props.config.filter((y) => y.id == btn.id)[0]
        .addClientFilter;
      let filterParams = this.props.config.filter((y) => y.id == btn.id)[0]
        .filterParams;
      let replaceString = this.props.config.filter((y) => y.id == btn.id)[0]
        .replaceString;

      if (
        this.props.app.filterObject.tenant.length > 0 &&
        btn.addClientFilter == true
      ) {
        let tenants = this.props.app.filterObject.tenant;

        tenants = tenants.join();
        let n = btn.url.indexOf("?");
        switch (urlType.toUpperCase()) {
          case "APPEND":
            this.currentUrl = this.AppendUrlString(
              btn.url,
              replaceString,
              tenants,
              n
            );
            break;
          case "REPLACE": //sisense

            isSisenseUrl = btn.visualizationType == "sisense" || btn.visualizationType == null;
            if (isSisenseUrl) {
              this.currentUrl = btn.url;
            } else {
              this.currentUrl = this.ReplaceUrlString(
                btn.url,
                filterParams,
                replaceString,
                tenants,
                n
              );
            }
            break;
          default:
            this.currentUrl = btn.url;
            break;
        }
        if (urlUpper == 'REPLACE' && isSisenseUrl && !btn.isOpenInNewWindow && this.props.isConnected) { //sisense
          // if (btn.iandIApplicationClientDetails.length > 0) {
          //   this.setState({ showMaskedUnmasked: true })
          // }
          this.setState({ showSisenseModal: true, currentDashboardUrl: btn.url });
          // if (btn.url.includes("61ae1763b0c597003755a9b9")) { //Recon Advisor
          //   this.setState({ showReconFilter: true, currentDashboardUrl: btn.url });
          // } else if (btn.url.includes("60fecbbcac1320002d6d3a3a")) { //HR Manager
          //   this.setState({ showHRManagerFilter: true, currentDashboardUrl: btn.url });
          // }
          this.loadDash('sisenseModal', btn.url);
        } else if (btn.isOpenInNewWindow) {
          this.showConfirmAlert(btn.url);
        } else {
          this.setState({ currentDashboardUrl: this.currentUrl, showPopUpTableau: true });
        }
      } else {
        if (urlUpper == 'REPLACE' && isSisenseUrl && !btn.isOpenInNewWindow && this.props.isConnected) {
          // if (btn.iandIApplicationClientDetails.length > 0) {
          //   this.setState({ showMaskedUnmasked: true, currentDashboardUrl: btn.url })
          // }
          this.setState({ showSisenseModal: true, currentDashboardUrl: btn.url });
          // if (btn.url.includes("61ae1763b0c597003755a9b9")) { //Recon Advisor
          //   this.setState({ showReconFilter: true, currentDashboardUrl: btn.url });
          // } else if (btn.url.includes("60fecbbcac1320002d6d3a3a")) { //HR Manager
          //   this.setState({ showHRManagerFilter: true, currentDashboardUrl: btn.url });
          // }
          this.loadDash('sisenseModal', btn.url);
        } else if (btn.isOpenInNewWindow) {
          this.showConfirmAlert(btn.url);
        } else {
          this.setState({ currentDashboardUrl: btn.url, showPopUpTableau: true });
        }
      }
    }
  };
  loadDash = (divId, url) => {
    //main dashboard
    if (divId != 'drillDash') {
      //Get dashbord IDs
      this.setState({ showLoader: true });
      if (url) {
        let dashboardId = url.split("/");
        let dashId = dashboardId[dashboardId.length - 1];
        if (divId == "sisenseModal" && url.includes('filter=')) {
          this.props.currentApp.dashboards
            .load(dashboardId[dashboardId.length - 1])
            .then((dash) => this.afterLoadDash(dash, divId, url));
        } else {
          this.props.currentApp.dashboards
            .load(dashboardId[dashboardId.length - 1])
            .then((dash) => this.afterLoadDash(dash, divId, dashId));
        }
      }
    } else {
      //detail dashboard(widget modal)
      this.props.currentApp.dashboards
        .load(url)
        .then((dash) => this.afterLoadDash(dash, divId, url));
    }

  };
  handleFilterChange =(db)=>{
    this.setState({parentFilters:db.filters.$$items})
  }
  preLoadDash = (url) => {
    //Get dashbord IDs
    this.setState({ showLoader: true });
    if (url) {
      this.props.currentApp.dashboards
        .load(url)
        .then((dash) => this.afterLoadDash(dash, url, url));
    }

  };
  afterLoadDash = (db, divId, dashId) => {
    let clients;
    let tenantIds;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
      tenantIds = [...this.props.app.filterObject.tenantId]
    } else {
      let tenants = [];
      let ids = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
        ids.push(item.tenantID);
      });
      clients = [...tenants];
      tenantIds = [...ids]
    }
    if(divId != 'drillDash'){
      db.on("filterschanged",this.handleFilterChange);
      this.setState({parentFilters:db.filters.$$items});
    }
    //check for preload dashboards
    switch (db.id) {
      //Foundation overview
      case '606c97d5ecbae9002d951b71':
        this.setState({ preLoadfoundationOverview: db });
        break;
      //Automated overview
      case '5f7c12fd3965112230dbf2cd':
        this.setState({ preLoadAutomatedOverview: db });
        break;
      //Insight overview
      case '5f702a953965112230dbd29d':
        this.setState({ preLoadInsightOverview: db });
        break;
      //Intelligent overview
      case '5f92c8f85fd2f53f38600333':
        this.setState({ preLoadIntelligentOverview: db });
        break;
      default:
        break;
    }
    // if (dashId == '5f19722e48414a2300b179c7' || dashId == '5f37ba1d4ad12e24f8afb127' || dashId == '5f17e79000e5352f388da2d2' || dashId == '5f702a953965112230dbd29d' || dashId == '5fa1714c4be9354e24e4327c' || dashId == '5edde53a1b0bea07501ae340' || dashId == '6288a2097ba9ce00366023c4' || dashId == 'drillDash') { // dashId == '5f17f33100e5352f388da331'  --Need to add this ID post Sisense localization testing
   if (dashId == '6288a2097ba9ce00366023c4' || divId == 'drillDash'  || dashId == "5fb8ddabbc46fb0af4f8eadf" || dashId == '5edde53a1b0bea07501ae340' ||dashId == '5fa1714c4be9354e24e4327c' ) { // dashId == '5f17f33100e5352f388da331'  --Need to add this ID post Sisense localization testing
      let value = drillToDashWithParentFilter.some(element => {
        return dashId.includes(element.dashboardId);
      })
      if (value) {
        this.applyDbFilters(db, dashId);
      }
    } else {
      if (divId == "sisenseModal" && dashId.includes('filter=')) {
        let jaqlArray = dashId.split("filter=");
        this.applyDbFiltersFixed(db, dashId, jaqlArray[1]);
      } else {
        this.applyDbFilters(db, dashId);
      }
    }
    let widgets = [];
    const layout = db.$$model.layout.columns[0].cells;
    const wRoot = db.$$model.title.startsWith("Drill") ? document.getElementById('dbModal') : document.getElementById(divId)
    if (wRoot) wRoot.innerHTML = "";
    let i = 0;
    layout.forEach(col => {
      i++;
      const row = this.createRow(i);
      col.subcells.forEach(cell => {
        const width = this.state.bankWgt.includes(cell.elements[0].widgetid)? 50:(cell.width ? cell.width : 100);
        const hidden = cell.elements[0].widgetid === "66daf010173ed70033832d80" ? true : false;
        row.appendChild(this.createLayout(i, cell.elements[0], width, dashId, hidden));
        if (this.state.gphVersion == "gph4.0") {
          let value = iwGPHwidgets.some(element => {
            return cell.elements[0].widgetid.includes(element.dashboardId);
          })
          if (!value) {
            widgets.push(cell.elements[0].widgetid);
          }
        } else if ((this.state.gphVersion == 'iwgph' || this.state.gphVersion == null) && clients.length > 0) {
          let value = gph4Widgets.some(element => {
            return cell.elements[0].widgetid.includes(element.dashboardId);
          })
          if (!value) {
            widgets.push(cell.elements[0].widgetid);
          }
        } else {
          widgets.push(cell.elements[0].widgetid);
        }
      })
      if (wRoot) {
        wRoot.appendChild(row);
      }
      if (i === layout.length) setTimeout(() => this.loadWgt(db, widgets, divId), 1000);
    });

  }

  createRow = (i) => {
    const row = document.createElement('div');
    row.className = "row";
    row.style.marginLeft = "0px";
    row.style.marginRight = "0px";
    row.id = "row" + i;
    return row;
  }

  createLayout = (i, el, width, dashId, hidden) => {
    const headerDiv = document.createElement("div");
    headerDiv.id = "head" + el.widgetid;
    headerDiv.style.padding = "5px 20px";
    headerDiv.style.color = "white";
    headerDiv.style.borderTopRightRadius = '10px';
    headerDiv.style.borderTopLeftRadius = '10px';
    headerDiv.style.position = 'relative';
    if (dashId == '606c97d5ecbae9002d951b71' || dashId == '5f7c12fd3965112230dbf2cd' || dashId == '5f702a953965112230dbd29d' || dashId == '5f92c8f85fd2f53f38600333') {
    } else {
      headerDiv.style.backgroundColor = "#0242C6";
    }

    const height = el.height === "" || el.height === 512 ? "100%" : el.height.toString().replace("px", "") + "px";
    const div = document.createElement('div');
    div.id = el.widgetid;
    div.style.height = height;
    if (dashId == '606c97d5ecbae9002d951b71' || dashId == '5f7c12fd3965112230dbf2cd' || dashId == '5f702a953965112230dbd29d' || dashId == '5f92c8f85fd2f53f38600333') {
      div.style.backgroundColor = "rgb(0, 0, 68)";
    } else if(el.widgetid == '62bc54255e8ec600365dc57a'){
      div.style.backgroundColor = "#000441";
    } else {
      div.style.backgroundColor = "#2291FF";
    }

    const pdiv = document.createElement('div');
    pdiv.className = "layout row" + i
    pdiv.style.width = "calc(" + width + "% - 4px)";
    pdiv.style.margin = "2px";
    pdiv.style.display= hidden?"none":"block";

    pdiv.appendChild(headerDiv);
    pdiv.appendChild(div);

    return pdiv;
  }
  loadWgt = (db, widgets, divId) => {
    let clients;
    let tenantIds;
    if (this.props.app.filterObject.tenant.length > 0) {
      clients = [...this.props.app.filterObject.tenant];
      tenantIds = [...this.props.app.filterObject.tenantId]
    } else {
      let tenants = [];
      let ids = [];
      this.props.userDetails.tenant.map((item) => {
        tenants.push(item.pseudoName);
        ids.push(item.tenantID);
      });
      clients = [...tenants];
      tenantIds = [...ids]
    }

    let i = 0;
    const listVal = [];
    widgets.forEach(wgt => {
      i++;
      const div = document.getElementById(wgt);
      div.innerHTML = "";
      div.style.width = "100%";
      const oWgt = db.widgets.get(wgt);
      if (oWgt) {
        const head = document.getElementById("head" + wgt);
        if (oWgt.title.length > 0 && oWgt.title !== "RICHTEXT_MAIN.TITLE")
          head.innerHTML = oWgt.title;
        else
          if (head) head.style.display = "none";

        if (!oWgt.container)
          oWgt.container = div;
        else {
          const clone = Object.assign(oWgt);
          clone.$$container = null;
          clone.container = div;
        }
        oWgt.refresh();
        const recurse = () => {
          if (oWgt.refreshing)
            setTimeout(recurse, 1000);
          else {
            if (oWgt.queryResult) {
              let wTxt;
              if (oWgt.queryResult.length) {
                wTxt = oWgt.queryResult[0][0].Value;
              }
              else {
                wTxt = oWgt.queryResult.value ? oWgt.queryResult.value.text : "";
              }

              switch (db.id) {
                case "5eb925e2bb2a332c80394dd8": //F&A
                  {
                    const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                    const Class = div.parentElement.className.replace("layout ", "");
                    listVal.push({ "class": Class, "val": val });
                    if (i === widgets.length && i === listVal.length) {
                      let result = [];
                      let j = 0;
                      listVal.forEach((v) => {
                        j++;
                        if (!result[v.class]) result[v.class] = 0;
                        result[v.class] += v.val;
                        if (j === listVal.length) {
                          const wCont = document.getElementById(divId);
                          wCont.childNodes.forEach(el => {
                            if (result[el.id] === 0) el.style.display = 'none';
                          })
                        }
                      })
                    }
                  }
                  break;
                case "606c97d5ecbae9002d951b71": //Foundation-Overview
                  // if (db.$$filters.$$filters[0].$$model.levels[1].filter.all) { } //Pseudo client filter
                  if (db.$$filters.$$filters[1].$$model.jaql.filter.all) { }  //Tenant id filter
                  else {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) > 6) {
                      //// check if it is AIG client. if yes show ACF row
                      // if(parseInt(div.parentElement.parentElement.id.replace("row", "")) == 8 && this.props.app.filterObject.tenantId[0] == window.tenantAIG.id){
                      // //// Don't hide ACF row
                      // }else{
                      div.parentElement.parentElement.style.display = "none";
                      // }
                    }
                  }
                  if (this.state.gphVersion == "gph4.0") {

                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 2) {
                      document.getElementById("row2").style.display = "none";
                    }
                    iwGPHwidgets.some(element => {
                      document.getElementById(element.dashboardId).style.display = "none";
                      document.getElementById(element.dashboardId).parentElement.parentElement.style.display = "none";
                    })
                  } else if (this.state.gphVersion == 'iwgph' || this.state.gphVersion == null) {

                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 3) {
                      document.getElementById("row3").style.display = "none";
                    }
                    gph4Widgets.some(element => {
                      document.getElementById(element.dashboardId).style.display = "none";
                      document.getElementById(element.dashboardId).parentElement.parentElement.style.display = "none";
                    })
                  }
                  if (wTxt === "0") {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                case "5f7c12fd3965112230dbf2cd"://5f6e3bf6575c7c247c68f045 //automated overview
                  if (wTxt === "0") {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  // if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 2) {
                  //   div.parentElement.parentElement.style.display = "none";
                  // }
                  break;
                case "5f92c8f85fd2f53f38600333": //Intelligent Overview
                  if (wTxt === "0") {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break
                case "5f702a953965112230dbd29d": //Insight-driven
                  // if (db.$$filters.$$filters[0].$$model.levels[1].filter.all) { } //Pseudo client filter
                  if (db.$$filters.$$filters[1].$$model.jaql.filter.all) { }  //Tenant id filter
                    else {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 9) {
                      div.parentElement.parentElement.style.display = "none";
                    }
                  }
                  // Insights-Driven -> Overview -> Supplier Delivery Assurance (7), Talent Demand Forecaster (8)​, work force manager(10)
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 7) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 8) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 10) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                case "5f19722e48414a2300b179c7":
                  // Insigts-Driven -> IFO -> Period End Accelerator(4) , Balance Sheet Analyzer(3)
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 3) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 4) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                case "5f17e79000e5352f388da2d2":
                  // Insigts-Driven -> Talent & HR services -> Talent Demand Forecaster(1) , IRF Insights(7), Recruitment Funnel Analyzer Weekly(3)
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 1) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 3) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 7) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                case "5f17f33100e5352f388da331": //Insight-driven cross offering
                  // if (db.$$filters.$$filters[0].$$model.levels[1].filter.all) { } //Pseudo client filter
                  if (db.$$filters.$$filters[1].$$model.jaql.filter.all) { }  //Tenant id filter
                    else {
                    if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 2) {
                      div.parentElement.parentElement.style.display = "none";
                    }
                  }
                  // Insigts-Driven -> Cross Offering -> Work Force Manager(1)
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 1) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                case "5f16ad3248414a2300b17438":
                  // Intelligent -> IFO -> Intelligent Touchless Accounts Payable(9)
                  if (parseInt(div.parentElement.parentElement.id.replace("row", "")) == 9) {
                    div.parentElement.parentElement.style.display = "none";
                  }
                  break;
                // case "5f16ad3248414a2300b17438": //Intelligent F&A
                //   {
                //     const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                //     const Class = div.parentElement.className.replace("layout ", "");
                //     listVal.push({ "class": Class, "val": val });
                //     if (i === widgets.length && i === listVal.length) {
                //       let result = [];
                //       let j = 0;
                //       listVal.forEach((v) => {
                //         j++;
                //         if (!result[v.class]) result[v.class] = 0;
                //         result[v.class] += v.val;
                //         if (j === listVal.length) {
                //           const wCont = document.getElementById(divId);
                //           wCont.childNodes.forEach(el => {
                //             if (result[el.id] === 0) el.style.display = 'none';
                //           })
                //         }
                //       })
                //     }

                //   }
                //   break;
                case "5f36a130bedb412db43f6898": //Intelligent Cross Offering
                  {

                    const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;
                    const Class = div.parentElement.className.replace("layout ", "");
                    listVal.push({ "class": Class, "val": val });
                    if (i === widgets.length && i === listVal.length) {
                      let result = [];
                      let j = 0;
                      listVal.forEach((v) => {
                        j++;
                        if (!result[v.class]) result[v.class] = 0;
                        result[v.class] += v.val;
                        if (j === listVal.length) {
                          const wCont = document.getElementById(divId);
                          wCont.childNodes.forEach(el => {
                            if (result[el.id] === 0) el.style.display = 'none';
                          })
                        }
                      })

                    }
                  }
                  break;
                case "5f33bfa34ad12e24f8afa6b7": //Intelligent Network
                  {

                    const val = (wTxt === "#N/A" || wTxt === "N\\A" || wTxt === "0" || wTxt === 0) ? 0 : 1;

                    const Class = div.parentElement.className.replace("layout ", "");
                    listVal.push({ "class": Class, "val": val });
                    if (i === widgets.length && i === listVal.length) {
                      let result = [];
                      let j = 0;
                      listVal.forEach((v) => {
                        j++;
                        if (!result[v.class]) result[v.class] = 0;
                        result[v.class] += v.val;
                        if (j === listVal.length) {
                          const wCont = document.getElementById(divId);
                          wCont.childNodes.forEach(el => {
                            if (result[el.id] === 0) el.style.display = 'none';
                          })
                        }
                      })

                    }
                  }
                  break;
                default: break;
              }
            }
            // if(oWgt.type === "chart/line" || oWgt.type === "chart/column" || oWgt.type === "chart/bar" || oWgt.type === "chart/pie"  || oWgt.type === "chart/area" || oWgt.type === "treeMapByQbeeQ" || oWgt.type === "sunburst"){
            //   const div=document.getElementById(wgt);
            //   if(div) div.style.zoom = "132.5%"; 
            //   const oWgt = db.widgets.get(wgt);
            //   oWgt.refresh(); 
            // } 
            //Banking dashboard
            oWgt.on('ready',()=>{
              if(oWgt.type && (oWgt.type === "TabberByQbeeQ")){
                  //console.log(div);
                  const tabber = document.getElementsByClassName("listDefaultCSS");
                  tabber[0].childNodes.forEach(el=> {
                      if(el.className ==='listItemDefaultCSS')
                          el.onclick = (e) => {
                              clearstyle();
                              e.currentTarget.classList.add("tabberAct")
                              if(e.currentTarget.textContent.toLowerCase()==="user"){
                                  hideWgt("66daf010173ed70033832d7f");
                                  showWgt("66daf010173ed70033832d80");
                              }
                              else{
                                  showWgt("66daf010173ed70033832d7f");
                                  hideWgt("66daf010173ed70033832d80");
                              }
                          }
                  })
              }    
          })
          const hideWgt = (wgt) =>{
            const div=document.getElementById(wgt);
            if(div) div.parentElement.style.display = "none";   
            }
            const showWgt = (wgt) =>{
                const div=document.getElementById(wgt);
                if(div) div.parentElement.style.display = "block";
                const oWgt = db.widgets.get(wgt);
                oWgt.refresh();
            }
        
            const clearstyle = () =>{
                const tabber = document.getElementsByClassName("listDefaultCSS");
                tabber[0].childNodes.forEach(el=> {
                    el.removeAttribute('style');
                    el.classList.remove("tabberAct");
                })    
            }

            if (oWgt.$$model.options.drillTarget) {
              const drillDB = oWgt.$$model.options.drillTarget.oid ? oWgt.$$model.options.drillTarget.oid : oWgt.$$model.options.drillTarget
              if (drillDB !== "dummy") {
                div.parentElement.onclick = () => this.handleModal(drillDB);
              div.parentElement.style.cursor = "pointer";
              }
            }
            setTimeout(() => { if (div.style.height === "auto") div.style.height = "100%"; }, 2000)

          }
        }
        recurse();
      }
      if (i == widgets.length) {
        if(this.state.showDashModal){
          let activeDrillDashObj={
            activeDahObj:db, activeWidgets:widgets, activeDivId:divId
          }
          this.setState({activeDrillDashObj});
        }else if(this.state.showSisenseModal){
          let activeButtonDashObj={
            activeDahObj:db, activeWidgets:widgets, activeDivId:divId
          }
          this.setState({activeButtonDashObj});
        }else{
          let activeSubTabDashObj={
            activeDahObj:db, activeWidgets:widgets, activeDivId:divId
          }
          this.setState({activeSubTabDashObj});
        }
        db.refresh();
      }
    })
    this.setState({ showLoader: false });

  }
  handleTabChange = (value, defaultSubTab) => {
    let subTab;
    switch (value) {
      case 0:
        this.setState({
          showDefault: true,
          tabParentId: 0,
        });
        break;
      case 1:
        {
          if(defaultSubTab == 0){
            subTab = this.state.subTabs
            .filter((item) => item.parentID == 1)
            .sort((a, b) => {
              return a.position - b.position;
            })[0];
          }else{
            subTab = this.state.subTabs
            .filter((item) => (item.parentID == 1 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: subTab ? false : true,
            tabParentId: 1,
            modalbreadCrum: "Foundation",
            showPopUp: true
          });
        }
        break;
      case 2:
        {
          if(defaultSubTab == 0){
            subTab = this.state.subTabs
            .filter((item) => item.parentID == 2)
            .sort((a, b) => {
              return a.position - b.position;
            })[0];
          }else{
            subTab = this.state.subTabs
            .filter((item) => (item.parentID == 2 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: subTab ? false : true,
            tabParentId: 2,
            modalbreadCrum: "Automated",
            showPopUp: true
          });
        }
        break;
      case 3:
        {
          if(defaultSubTab == 0){
            subTab = this.state.subTabs
            .filter((item) => item.parentID == 3)
            .sort((a, b) => {
              return a.position - b.position;
            })[0];
          }else{
            subTab = this.state.subTabs
            .filter((item) => (item.parentID == 3 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: subTab ? false : true,
            tabParentId: 3,
            modalbreadCrum: "Insight-Driven",
            showPopUp: true
          });
        }
        break;
      case 4:
        {
          if(defaultSubTab == 0){
            subTab = this.state.subTabs
            .filter((item) => item.parentID == 4)
            .sort((a, b) => {
              return a.position - b.position;
            })[0];
          }else{
            subTab = this.state.subTabs
            .filter((item) => (item.parentID == 4 && item.id == defaultSubTab))[0];
          }
          this.setSubTab(subTab);
          this.setState({
            showDefault: subTab ? false : true,
            tabParentId: 4,
            modalbreadCrum: "Intelligent",
            showPopUp: true
          });
        }
        break;
      default:
        break;
    }
  };
  handleModal = (drillDB) => {
    this.setState({ showDashModal: true })
    this.loadDash("drillDash", drillDB);
  }
  logout = () => {
    sessionStorage.clear();
    localStorage.clear();
    (() => {

      let cookies = document.cookie.split(";");
      for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";

      }
    })();
    window.location.href =

      "https://login.microsoftonline.com/common/wsfederation?wa=wsignout1.0";

  };

  onClientTypeChange(e, data) {
    this.setState({ selectedClientType: data.value });
  }
  onClientPseudoTenantIdChange(data, index) {
    switch (this.state.selectedClientType) {
      // case "tenantId":
      // this.setState({selectedTenantId:data.value, selectedPseudoName:this.state.pseudoNamesList[index].value, seletedClientName:this.state.clientNamesList[index].value});
      //   break;
      case "pseudo":
        this.setState({ selectedTenantId: this.state.tenantIdsList[index].value, selectedPseudoName: data.value, seletedClientName: this.state.clientNamesList[index].value });
        break;
      case "client":
        this.setState({ selectedTenantId: this.state.tenantIdsList[index].value, selectedPseudoName: this.state.pseudoNamesList[index].value, seletedClientName: data.value });
        break;
    }
  }
  onDeploymentTypeChange(e, data) {
    var val = data.value;
    this.setState({ deploymentType: val })
  }
  onOutputVarianceChange(e, data) {
    var val = data.value;
    this.setState({ outputVariance: val })
  }
  onApply() {
    this.loadDash('sisenseModal', this.state.currentDashboardUrl);
  }
  onReset() {
    this.setState({
      deploymentType: buttonFilters[0].data[0].value,
      outputVariance: buttonFilters[1].data[0].value,
      selectedClientType: "pseudo",
      seletedClientName: "all",
      selectedPseudoName: "all",
      selectedTenantId: "all"
    })
    this.loadDash('sisenseModal', this.state.currentDashboardUrl);
  }
  handleTabSelect = (selectedTab) => {
    this.setState({ selectedTab });
  }

  //SVM functions
  
  onStart = () => {
    this.setState({ isStart: true });
    const programID = this.state.programID;
    this.createLog({ programID });
};

createLog = (props) => {
    /* Optional function if enableLogger set to false or not declared*/
}

onKnowMore = () => {
   this.setState({ isKnowMore: true });
};
onClickBackBtn = () => {
  this.setState({ isKnowMore: false }, () => {
      
  });
};

GetAuthObj = async() => {
  if (ENVIRONMENT !== 'local') {
    const account = msalInstance.getAllAccounts()[0];
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
//always call the acquiretokensilent first before calling any API
const response = await msalInstance.acquireTokenSilent({
  ...loginRequest,
  account: account
}).catch(err => {
  //as a fallback so that user does not go away from the page when acquiretokensilent fails, use acquireTokenPopup method
  //always check if the error is something that requires auth login interaction 
  if (err instanceof InteractionRequiredAuthError) {
    return msalInstance.acquireTokenPopup({
      ...loginRequest,
      account: account
    }).catch(err => {
      console.log('Error getting popup token: ' + JSON.stringify(err))
    });
  }
});
// GetAuthObj function should return an object below
const data = {
  apiHttps: SVM_URL,//SVM_COMMON_URL,
  accessToken: response.accessToken
};
return data;
}

}

clearDashboard = async(key) => {
try {
switch (key) {
  case 'reportingYear':
  this.setState({
    dimensionSubDimension: [],
    programID: '',
    dbDimensionList: undefined,
  });
  break;
  case 'program':
  this.setState({
    dimensionSubDimension: [],
    programID: '',
    dbDimensionList: undefined,
  });
  break;
  default:
  this.setState({
    reportingYear: [],
    dashboardProgram: [],
    dimensionSubDimension: [],
    programID: '',
    dbDimensionList: undefined,
  });
  break;
}
} catch (err) {
console.log(err);
}
}

// Required Callback functions
fetchDashboardDataCallback = async (data) => {
  if (data.dashboardData.length>0) {
  // console.log("fetchDashboardDataCallback", data);
  const selectedProgram = {
    id: String(data.dashboardData[0].id),
    label: data.dashboardData[0].programName,
    value: data.dashboardData[0].programName,
  };
  const year = data.dashboardData[0].ReportingYear;
  const selectedYear = { label: [year], value: [year] };
  
  const reportingYear = [];
  let defaultYear = {}
  
  data.dashboardData.filter(x => x.id == data.dashboardData[0].id).map((elem)=>{
    reportingYear.push({ label: [elem.ReportingYear], value: [elem.ReportingYear] })
  })
  let currentYear = new Date().getFullYear();
  let isPresentYear = reportingYear.some(val=>val.label[0]==currentYear);
  if(isPresentYear){
  defaultYear = { label: [currentYear], value: [currentYear] };
  }else{
  defaultYear = {...selectedYear}
  }
  
  this.setState({
    dashboardProgram: data.dashboardProgram,
    dashboardData: data.dashboardData,
    selectedProgram,
    reportingYear:[selectedYear],
    selectedYear:defaultYear
  },
  () => {
      const reportingYear = [];
      data.dashboardData.filter(x => x.id == data.dashboardData[0].id).map((elem)=>{
          reportingYear.push({ label: [elem.ReportingYear], value: [elem.ReportingYear] })
      })
    this.setState({
      reportingYear
    });
  })
  }
  }
fetchClientDetailsCallback = async (data) => {
if (data) {
// console.log("fetchClientDetailsCallback", data);
this.setState({
  dashboardClientDetails: data.dashboardClientDetails,
  isInitialized: true,
})
}
}
onSelectProgramCallback = async (data, selProgram) => {
if (data) {
// console.log("onSelectProgramCallback", data);
this.setState({
  reportingYear: data.reportingYear
})

if (this.state.isStart) {
  /* this.props.setToasterParam({
  show: true,
  type: 'success',
  message: 'Program data loaded successfully.',
  }); */

  let isPresent = false;
if(this.state.selectedYear){
    isPresent = data.reportingYear.some(val=> this.state.selectedYear.value[0]==val.value[0])
  }  
  this.setState({
  selectedProgram: selProgram,
  selectedYear:isPresent?this.state.selectedYear:data.reportingYear[0]
  })
} else {
  this.setState({
  selectedYear: '',
  selectedProgram: selProgram,
  });
}
}
}

setSelectedReportingYearCallback = async (e) => {
if (!this.state.startButton) {
this.clearDashboard('reportingYear');
}
if (this.state.selectedProgram === '') {
this.setState({
  selectedYear: e,
  isStart: false,
});
} else {
this.setState(
  {
  selectedYear: e,
  },
  () => {
  //  if (this.state.isStart) {
    // this.props.setToasterParam({
    // show: true,
    // type: 'success',
    // message: 'Program data loaded successfully.',
    // });
  // } 
  }
);
}
}
getDashboardDimensionsCallback = async (data) => {
if (data) {
// console.log("getDashboardDimensionsCallback", data);
this.setState({
  programFiscalYearRange: data.programFiscalYearRange,
  dbDimensionList: data.dbDimensionList,
  dimensionSubDimension: data.dimensionSubDimension,
  programID: data.programID,
  dashboardProgramDetails: data.dashboardProgramDetails
})
}
}
setCommentaryRequiredDataCallback = async (data) => {
 if (data) {
// console.log("setCommentaryRequiredDataCallback", data);
this.setState({
  dashboardCommentary: data.dashboardCommentary,
})
 }
}
onClickBackBtn = () => {
  this.setState({ isKnowMore: false }, () => {
    // this.props.panelOnClick(null, false);
  });
};

  render() {
   // console.log(this.props.selectedClient,"selected");

    const rowEvents = {
      onClick: (e, row, rowIndex) => {
        let getCurrentCellIndex = e.target.cellIndex;
        let clientObj={pseudoName:"",tenantName:"", tenantID:""}
        if(getCurrentCellIndex || getCurrentCellIndex==0){
          switch(row.screenSection){
            case "T1":{
              this.props.config.map(item=>{
                if(item.id == row.configId){
                  this.setState({
                    hideAnnouncementHolderDiv: true, showAnnouncements: false
                  })
                  this.setState({alertObject:{
                      configId:row.configId,
                      configParentId:row.configParentId
                    }
                  })
                  if(row.tenantId){
                    clientObj.tenantName=row.client;
                    clientObj.pseudoName=row.pseudoName;
                    clientObj.tenantID=row.tenantId;
                    this.props.onSelectClient(clientObj)
                  }
                  // let tabParentId = row.configParentId;
                  // let defaultSubTab = row.configId;
                  // this.handleTabChange(tabParentId, defaultSubTab)
                }
              })
            }
              break;
            case "T3":{
                  this.setState({
                    hideAnnouncementHolderDiv: true, showAnnouncements: false
                  })
                  if(row.tenantId){
                    clientObj.tenantName=row.client;
                    clientObj.pseudoName=row.pseudoName;
                    clientObj.tenantID=row.tenantId;
                    this.props.onSelectClient(clientObj)
                  }
                  // this.setActiveScreen("T3");
            }
              break;
            case "T4":{
                  this.setState({
                    hideAnnouncementHolderDiv: true, showAnnouncements: false
                  })
                  if(row.tenantId){
                    clientObj.tenantName=row.client;
                    clientObj.pseudoName=row.pseudoName;
                    clientObj.tenantID=row.tenantId;
                    this.props.onSelectClient(clientObj)
                  }
                  // this.setActiveScreen("T4");
            }
              break;
            default:
              break;
          }
        }
      }
    }
					   
					
	const columns = [
      {
        dataField: "section",
        text: "Section",
        title : true
      },
      {
        dataField: "subsection",
        text: "Subsection",
        title : true
      },
      {
        dataField: "application",
        text: "Application",
        title : true
      },
      {
        dataField: "client",
        text: "Client",
        title : true
      },
      {
        dataField: "kpi",
        text: "KPI",
        title : true
      },
      {
        dataField: "kpivaluehidden",
        text: "KPI Value",
        hidden:true
      },
      {
        dataField: "kpivalue",
        text: "KPI Value",
        formatter: (cell, row) => {
          switch(row.ragStatus){
            case "G":
              return (<button className="green-alert">
                    {row.kpivalue}
                  </button>)
            case "Y":
              return (<button className="yellow-alert">
                    {row.kpivalue}
                  </button>)
            case "A":
              return (<button className="amber-alert">
                    {row.kpivalue}
                  </button>)
            case "R":
              return (<button className="red-alert">
                    {row.kpivalue}
                  </button>)
            default:
            break;
          }

        },
        csvExport: false,
      },
     // {
     //   dataField: "target",
     //   text: "Target",
     // },
      {
        dataField: "configId",
        csvExport: false,
        hidden:true
      },
      {
        dataField: "configParentId",
        csvExport: false,
        hidden:true
      },
      {
        dataField: "screenSection",
        csvExport: false,
        hidden:true
      },
      {
        dataField: "ragStatus",
        csvExport: false,
        hidden:true
      },
      // {
      //   dataField: "dismiss",
      //   //  text: "Dismiss"
      //   csvExport: false,
      // }
    ];
    let products = [];

    this.state.alertsDataB9.map((item) => {
      products.push({
        section: item.iojStage,
        subsection: item.subsection,
        application: item.application,
        client: item.client,
        kpi: item.kpi,
        kpivaluehidden:item.kpiValue,
        kpivalue: <>{item.kpiValue} <img className="dismissClosebtn-b9" title="Dismiss" onClick= {() => this.getDismissedData(item.id)} src={require("../../Images/drafting-compass Copy.svg")} /></>,
      //  target: item.target,
		    configId:item.configId,
        configParentId:item.configParentId,
        screenSection:item.screenSection,
        ragStatus:item.ragStatus,	
        pseudoName: item.pseudoName,
        tenantId: item.tenantId,
        // dismiss: <img className="dismissClosebtn" onClick= {() => this.getDismissedData(item.id)} src={require("../../Images/drafting-compass Copy.svg")} />
      });
    });
    const columnHover = (cell, row, enumObject, rowIndex) => {
      return cell;
    }
    const optionsPag = {
      sizePerPageList: [
        {
          text: "6",
          value: 6,
        },
        {
          text: "12",
          value: 12,
        },
        {
          text: "18",
          value: 18,
        },
      ],
    paginationSize: 4, // the pagination bar size.
    lastPageText: <img className="arrowHeight" src={require('../../Images/icon_exnext_white.svg')}/>,
    // lastPageText: <img className="arrowHeight" src={require('../../Images/icon_exnext_white.svg')}/>,
    // firstPageText: <img  className="arrowHeight" src={require('../../Images/icon_expre_white.svg')}/>,
    // nextPageText: <img  className="arrowHeight" src={require('../../Images/icon_exnex_gray.svg')}/>,
    // prePageText: <img  className="arrowHeight" src={require('../../Images/icon_pre_white.svg')}/>,
    firstPageText: '<<',
    prePageText: '<',
    nextPageText: '>',
    lastPageText: '>>',
						  
    pageStartIndex: 1, // where to start counting the pages
    // withFirstAndLast: true,
    alwaysShowAllBtns: true,
    hideSizePerPage: true,
    };

  

    const handleClose = () => this.setState({ showDashModal: false, maximizeIOJPopup: false });
    const handlePowerBiClose = () => this.setState({ showPowerBiModal: false, maximizeIOJPopup: false });
    const handlePowerBiWithoutTokenClose = () => this.setState({ showPowerBiWithoutTokenBtn: false, maximizeIOJPopup: false });

    let handleAnnouncementClose = () => {
      this.props.switchHumanAndMachineTab(false);
    }
    let compare = (a, b) => {
      return a.position - b.position;
    };
    const handleFilt = () => {
      if (this.state.showDashFilt) {
        this.setState({ showDashFilt: false, filterText: "»" })
      } else {
        this.setState({ showDashFilt: true, filterText: "«" })
      }
    }
    const handleFiltModal = () => {
      if (this.state.showDashFiltModal) {
        this.setState({ showDashFiltModal: false, filterTextModal: "»" })
      } else {
        this.setState({ showDashFiltModal: true, filterTextModal: "«" })
      }
    }

    //Common url buttons component for all the sub tabs
    let activeUrlButtons = this.state.activeUrlButtons
      .sort(compare)
      .map((btn) => (
        <button
          className="hmcc-drop-btn btn-center position-relative"
          onClick={() => this.handleUrlButton(btn)}
          style={{ fontSize: "0.9rem" }}
        >
          {i18n.t(btn.displayName)}
        </button>
      ));
    const sisenseDashboard = () => {
      return this.state.allSubTabs.map((db, i) => {
        if (db.url) {
          let isSisenseUrl = true;
          let isPowerBiUrl = true;
          isSisenseUrl = db.visualizationType == "sisense" || db.visualizationType == null;
          isPowerBiUrl = db.visualizationType == "powerbi";

          if (db.isOpenInNewWindow) {
            return (
              <div
                data-test="testDashboardContainer"
                id={db.id}
                key={db.id}
                style={
                  this.state.sisenseStyle.length > 0
                    ? this.state.sisenseStyle.filter((p) => p.id === db.id)[0]
                      ? this.state.sisenseStyle.filter((q) => q.id === db.id)[0]
                        .style
                      : { display: "none" }
                    : { display: "none" }
                }
                // className={activeUrlButtons.length > 0 ? `hide-overflow subtab-height ${this.state.filterApplied ? "change-width" : " "
                //   }` : `hide-overflow subtab-height1 ${this.state.filterApplied ? "change-width" : " "
                // }`}
                className={activeUrlButtons.length > 0 ?
                  `hide-overflow ${!this.state.maximizeIOJDashboard ? "subtab-height normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9"}  ${this.state.filterApplied ? "change-width" : " "}` :
                  `hide-overflow ${!this.state.maximizeIOJDashboard ? "subtab-height1 normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9"}  ${this.state.filterApplied ? "change-width" : " "
                  }`}
              >
                <span className="new-tab-message" style={{ color: "white" }}>{i18n.t("This App opens in separate window")}</span>

              </div>
            );

          } else if (isSisenseUrl) {
            let dashboardId = db.url.split("/");
            let dashId = dashboardId[dashboardId.length - 1];
            let filterId = "filter" + dashId;

            return (
              <div>
                <div
                  data-test="testDashboardContainer"
                  id={dashId}
                  key={db.id}
                  style={
                    this.state.sisenseStyle.length > 0
                      ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                        ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                          .style
                        : { display: "none" }
                      : { display: "none" }
                  }
                  // className={activeUrlButtons.length > 0 ? `subtab-height ${this.state.filterApplied ? "change-width" : " "
                  //   }` : `subtab-height1 ${this.state.filterApplied ? "change-width" : " "
                  // }`}

                  className={activeUrlButtons.length > 0 ?
                    `${!this.state.maximizeIOJDashboard ? "subtab-height normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width " : " "}` :
                    `${!this.state.maximizeIOJDashboard ? "subtab-height1 normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width " : " "}`}

                >
                </div>
                <div
                  style={{ visibility: "hidden" }}
                  // style={{visibility:this.state.isSisenseSubtab?"visible":"hidden"}} 
                  className={this.state.showDashFilt ? "filtPaneShow" : "filtPane"} onClick={handleFilt}>{this.state.filterText}</div>
                <div className="filterPanelHide"
                //   className={this.state.showDashFilt?"filterPanel":"filterPanelHide"}
                >
                  <span>Filters</span><hr style={{ marginTop: "5px", marginBottom: "0px" }} />
                  <div id={filterId} className="filtContainer" style={
                    this.state.sisenseStyle.length > 0
                      ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                        ? this.state.sisenseStyle.filter((o) => o.id === dashId)[0]
                          .style
                        : { display: "none" }
                      : { display: "none" }
                  }></div>
                </div>
              </div>
            );
          } else if (!isPowerBiUrl || !this.props.userDetails.isPowerBIEnabled) {
            return (
              <div
                data-test="testDashboardContainer"
                id={db.id}
                key={db.id}
                style={
                  this.state.sisenseStyle.length > 0
                    ? this.state.sisenseStyle.filter((p) => p.id === db.id)[0]
                      ? this.state.sisenseStyle.filter((q) => q.id === db.id)[0]
                        .style
                      : { display: "none" }
                    : { display: "none" }
                }
                // className={activeUrlButtons.length > 0 ? `subtab-height ${this.state.filterApplied ? "change-width" : " "
                //   }` : `subtab-height1 ${this.state.filterApplied ? "change-width" : " "
                // }`}
                className={activeUrlButtons.length > 0 ?
                  `${!this.state.maximizeIOJDashboard ? "subtab-height normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width" : " "}` :
                  `${!this.state.maximizeIOJDashboard ? "subtab-height1 normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width" : " "
                  }`}
              >
                {
                  (this.state.sisenseStyle.length > 0 && this.state.activeSubTab == db.displayName)
                    ? this.state.sisenseStyle.filter((p) => p.id === db.id)[0]
                      ? 
                      (this.state.sisenseStyle.filter((q) => q.id === db.id)[0].style.display != "none" && <iframe
                        className={this.state.maximizeIOJDashboard ? "subtab-iframe-maximized" : "subtab-iframe"}
                        src={this.state.sisenseStyle.length > 0
                          ? this.state.sisenseStyle.filter((o) => o.id === db.id)[0]
                            ? this.state.sisenseStyle.filter((o) => o.id === db.id)[0]
                              .url
                            : db.url
                          : db.url}
                      ></iframe>)
                      : null
                    : null
                }
              </div>
            );
          }

        }
      });
    };


    return (
            <>
        <div className='row'>

          <Modal
            backdrop="static"
            show={this.state.showPopUp}
            className="modal-width"
            onHide={this.hideModal}
            dialogClassName="modal-90w Leftmodal"
          >
            <Modal.Header className="closeButtonSize" closeButton style={this.state.maximizeIOJDashboard ? { borderBottom: "none", height: "0rem" } : { borderBottom: "none" }}>
              <div className=' m-0 b9ddc5maxwidth'>
                <div className='row'>
                  <div className='col-12 breadcrumbB9 my-3 ml-1'>
                    <a className='Home text-center' onClick={this.hideModal}>Home</a>
                    <a className='Clicked text-center' style={{ fontFamily: "GraphikMedium" }}>{this.state.modalbreadCrum}</a>
                  </div>
                </div>
                <div className='row ml-1'>
                  <div className='col-12 tab-pane fade show active'>
                    <div className="new-subTablist">
                      <nav id="newSubTablist" className="">
                     <div className='row' >
                          {this.state.subTabs ?
                        <Tabs
                          id='intOpsTabs'
                          indicatorColor="primary"
                          textColor="primary"
                          variant="scrollable"
                          scrollButtons="auto"
                          aria-label="scrollable auto tabs example"
                        >
                          {this.state.subTabs
                            .filter((item) => item.parentID == this.state.tabParentId)
                            .sort(compare)
                            .map((subTab) => (
                               <div className="position-relative">
                              <div
                                data-tip
                                data-for={subTab.displayName}
                                //title={i18n.t(subTab.toolTipText)}
                                className={
                                  this.state.activeSubTab == subTab.displayName
                                    ? "newTab-text newTab-text-active "
                                    : "newTab-text "
                                }
                                onClick={() => this.setSubTab(subTab)}
                              >
                                {i18n.t(subTab.displayName)}
                              </div>
                              </div>
                            ))}
                             </Tabs> : ""}
                        </div>
                      </nav>
                    </div>


                  </div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body style={{paddingTop:"0"}}>

              <div className='row'>
                <div className='col-12'>
                  <div className={`data-container remove-top-margin-b9 text-white ${this.state.maximizeIOJDashboard ? " maximizedIOJDashboardB9" : ""}`}>
                    <FontAwesomeIcon className={activeUrlButtons.length > 0 ? 'float-right' : 'float-right maxminIconAlignment'} style={{ cursor: "pointer" }}
                      onClick={() => this.handleMaxMin()}
                      color={"#fff"}
                      title={!this.state.maximizeIOJDashboard ? "Maximize" : "Minimize"}
                      icon={this.state.maximizeIOJDashboard ? faCompressAlt : faExpandAlt} />
                    <div className="hm2-d-flex">
                      <Tabs
                        indicatorColor="primary"
                        textColor="primary"
                        variant="scrollable"
                        scrollButtons="auto"
                        aria-label="scrollable auto tabs example"
                      >
                        {activeUrlButtons}
                      </Tabs>
                    </div>
                  </div>
                </div>
              </div>

              <div className="inner-wo-scroll">
                {!this.state.showPowerBi && !this.state.showPowerBiWithoutToken && (
                  (this.state.subTabs.filter((item) => item.parentID == this.state.tabParentId).length > 0) ?
                    (sisenseDashboard()) :
                    <h2 style={{ textAlign: "center", color: "white" }}>{i18n.t("No data available")}</h2>
                )}
                {this.state.showPowerBi && (
                  <div
                    id={this.state.powerBiReportId}
                    key={this.state.powerBiReportId}
                    className={activeUrlButtons.length > 0 ? `${!this.state.maximizeIOJDashboard ? "subtab-height normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width" : " "
                      }` : `${!this.state.maximizeIOJDashboard ? "subtab-height1 normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width" : " "
                      }`}
                  >
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: this.state.powerBiReportId,
                        embedUrl: this.state.powerBiEmbedUrl,
                        accessToken: null,    // Keep as empty string, null or undefined
                        tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                        filters: [this.state.powerBiFilterObj],
                        settings: {
                          panes: {
                            bookmarks: {
                              visible: false
                            },
                            fields: {
                              expanded: false
                            },
                            filters: {
                              expanded: false,
                              visible: true
                            },
                            pageNavigation: {
                              visible: false
                            },
                            selection: {
                              visible: true
                            },
                            syncSlicers: {
                              visible: true
                            },
                            visualizations: {
                              expanded: false
                            }
                          }
                        }
                      }}
                      cssClassName={`${this.state.maximizeIOJDashboard ? "height-max-powerbi-subtabB9 " : ""} power-subtab power-bi-frame`}
                      getEmbeddedComponent={(embeddedReport) => {
                        window.report = embeddedReport;
                        this.props.updatePBToken();
                      }}
                    />
                  </div>
                )}

                {this.state.showPowerBiWithoutToken && (
                  <div
                    key={this.state.powerBidirectUrl}
                    className={activeUrlButtons.length > 0 ? `${!this.state.maximizeIOJDashboard ? "subtab-height normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width" : " "
                      }` : `${!this.state.maximizeIOJDashboard ? "subtab-height1 normal-b9-subtab" : "subtab-height-maxmizeIOJDashboard-b9 "} ${this.state.filterApplied ? "change-width" : " "
                      }`}
                  >
                    <PowerBIEmbed
                      embedConfig={{
                        type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                        id: null,
                        embedUrl: this.state.powerBidirectUrl,
                        accessToken: null,    // Keep as empty string, null or undefined
                        tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                        settings: {
                          panes: {
                            bookmarks: {
                              visible: false
                            },
                            fields: {
                              expanded: false
                            },
                            filters: {
                              expanded: false,
                              visible: true
                            },
                            pageNavigation: {
                              visible: false
                            },
                            selection: {
                              visible: true
                            },
                            syncSlicers: {
                              visible: true
                            },
                            visualizations: {
                              expanded: false
                            }
                          }
                        }
                      }}
                      cssClassName={`${this.state.maximizeIOJDashboard ? "height-max-powerbi-subtabB9 " : ""} power-subtab power-bi-frame`}
                    />
                  </div>
                )}
              </div>
              <div className={this.state.showLoader ? "loading-b9" : "hidden"}>
                <img
                  className="loader-size"
                  src={require("../../Images/WO-background.gif")}
                  alt=""
                />
              </div>
            </Modal.Body>
          </Modal>

          <Modal
            backdrop="static"
            show={this.state.showPopUpAppStore}
            className="modal-width"
            onHide={this.hideModalAppStore}
            dialogClassName="modal-90w Leftmodal app-store-b9"
          >
            <Modal.Header closeButton style={{ borderBottom: "none", height: "0rem" }}>

            </Modal.Header>
            <Modal.Body>
              <AppStoreB9
                userDetails={this.props.userDetails}
                config={this.props.appStoreConfig}
                currentApp={this.props.currentApp}
                isConnected={this.props.isConnected}
                callUserEnagementSessionAPI={this.props.callUserEnagementSessionAPI}
                updatePBToken={this.props.updatePBToken} />
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>

          </Modal>
          <Modal
            backdrop="static"
            show={this.state.showPopUpTableau}
            className="modal-width"
            onHide={this.hideModalTableau}
            dialogClassName={this.state.maximizeIOJPopup ? "maximized-b9-popup LeftmodalTableau" : "modal-90w LeftmodalTableau"}
          >
            <Modal.Header closeButton style={{ borderBottom: "none", background: "#0058ff" }}>
              <FontAwesomeIcon className='maximizeIconIOJPopupB9' style={{ cursor: "pointer" }}
                onClick={() => this.handleMaxMinPopup()}
                color={"#fff"}
                title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
                icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
            </Modal.Header>
            <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-bodyb9" : "iframe-modal-body"} style={{ background: "#0058ff" }}>
              <iframe
                className={this.state.maximizeIOJPopup ? "modal-iframe-maximized-b9" : "modal-iframe"}
                src={this.state.currentDashboardUrl}
              ></iframe>
            </Modal.Body>
          </Modal>
          <Modal
            backdrop="static"
            show={this.state.showSisenseModal}
            className="modal-width"
            onHide={this.handleSisenseClose}
            dialogClassName={this.state.maximizeIOJPopup ? "maximized-b9-popup LeftmodalTableau" : "modal-90w LeftmodalTableau"}
          >
            <Modal.Header closeButton style={{ borderBottom: "none", background: "#0058ff" }}>
              {(this.state.showReconFilter || this.state.showHRManagerFilter || this.state.showMaskedUnmasked) && (<div className="css-filter-buttons align-right mt-20 mb-20 ioj-filter-buttons">
                {this.state.showMaskedUnmasked && (
                  <div style={{ marginLeft: "5px" }}>
                    <h7 style={{ fontWeight: 600, color: "white" }}>Masked/Unmasked:&nbsp;&nbsp;</h7>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedClientType}
                      onChange={(e) => this.setState({ selectedClientType: e.target.value })}
                    >
                      {
                        this.state.clientType.map((item) => {
                          return <MenuItem value={item.value}
                            onClick={(e) => this.onClientTypeChange(e, item)}>{item.label}</MenuItem>
                        })
                      }
                    </Select>
                  </div>
                )}
                 {/* {this.state.showMaskedUnmasked && this.state.selectedClientType =="tenantId" && (
                            <div style={{ marginLeft: "5px" }}>
                              <h7 style={{fontWeight: 600,color: "white"}}>Client:&nbsp;&nbsp;</h7>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.selectedTenantId}
                                onChange={(e) => this.setState({ selectedTenantId: e.target.value })}
                              >
                                {
                                  this.state.tenantIdsList.map((item, index) => {
                                    return <MenuItem value={item.value}
                                      onClick={(e) => this.onClientPseudoTenantIdChange(item, index)}>{item.label}</MenuItem>
                                  })
                                }
                              </Select>
                            </div>
                            )}  */}
                {this.state.showMaskedUnmasked && this.state.selectedClientType == "pseudo" && (
                  <div style={{ marginLeft: "5px" }}>
                    <h7 style={{ fontWeight: 600, color: "white" }}>Client:&nbsp;&nbsp;</h7>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.selectedPseudoName}
                      onChange={(e) => this.setState({ selectedPseudoName: e.target.value })}
                    >
                      {
                        this.state.pseudoNamesList.map((item, index) => {
                          return <MenuItem value={item.value}
                            onClick={(e) => this.onClientPseudoTenantIdChange(item, index)}>{item.label}</MenuItem>
                        })
                      }
                    </Select>
                  </div>
                )}
                {this.state.showMaskedUnmasked && this.state.selectedClientType == "client" && (
                  <div style={{ marginLeft: "5px" }}>
                    <h7 style={{ fontWeight: 600, color: "white" }}>Client:&nbsp;&nbsp;</h7>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.seletedClientName}
                      onChange={(e) => this.setState({ seletedClientName: e.target.value })}
                    >
                      {
                        this.state.clientNamesList.map((item, index) => {
                          return <MenuItem value={item.value}
                            onClick={(e) => this.onClientPseudoTenantIdChange(item, index)}>{item.label}</MenuItem>
                        })
                      }
                    </Select>
                  </div>
                )}

                {this.state.showReconFilter && (
                  <div style={{ marginLeft: "5px" }}>
                    <h7 style={{ fontWeight: 600, color: "white" }}>Deployment Type:&nbsp;&nbsp;</h7>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.deploymentType}
                      onChange={(e) => this.setState({ deploymentType: e.target.value })}
                    >
                      {
                        buttonFilters[0].data.map((item) => {
                          return <MenuItem value={item.value}
                            onClick={(e) => this.onDeploymentTypeChange(e, item)}>{item.label}</MenuItem>
                        })
                      }
                    </Select>
                  </div>
                )}
                {this.state.showHRManagerFilter && (<div style={{ marginLeft: "5px" }}>
                  <h7 style={{ fontWeight: 600, color: "white" }}>Output Variance:&nbsp;&nbsp;</h7>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.outputVariance}
                    onChange={(e) => this.setState({ outputVariance: e.target.value })}
                  >
                    {
                      buttonFilters[1].data.map((item) => {
                        return <MenuItem value={item.value}
                          onClick={(e) => this.onOutputVarianceChange(e, item)}>{item.label}</MenuItem>
                      })
                    }
                  </Select>
                </div>
                )}
                <div style={{ marginLeft: "5px" }}>
                  <button style={{ cursor: "pointer", borderStyle: "none" }} className="hmcc-drop-btn applyBtn" onClick={() => this.onApply()}>{i18n.t("Apply")}</button>
                </div>
                <div >
                  <button style={{ cursor: "pointer", borderStyle: "none" }} className="hmcc-drop-btn applyBtn" onClick={() => this.onReset()} >{i18n.t("Reset")}</button>
                </div>
              </div>
              )}
              <FontAwesomeIcon className='maximizeIconIOJPopupB9' style={{ cursor: "pointer" }}
                onClick={() => this.handleMaxMinPopup()}
                color={"#fff"}
                title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
                icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
            </Modal.Header>
            <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-bodyb9" : "iframe-modal-body"} style={{ background: "#0058ff" }}>
              <div id="sisenseModal" className={`db-container ${this.state.maximizeIOJPopup ? " b9-sisense-max-btn" : " "}`}></div>
            </Modal.Body>
          </Modal>
          <Modal
            backdrop="static"
            show={this.state.showDashModal}
            onHide={handleClose}
            className="modal-width"
            dialogClassName={this.state.maximizeIOJPopup ? "maximized-b9-popup LeftmodalTableau" : "modal-90w LeftmodalTableau"}
          >
            <Modal.Header closeButton style={{ borderBottom: "none", background: "#0058ff" }}>
              <FontAwesomeIcon className='maximizeIconIOJPopupB9' style={{ cursor: "pointer" }}
                onClick={() => this.handleMaxMinPopup()}
                color={"#fff"}
                title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
                icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
            </Modal.Header>
            <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-bodyb9" : "iframe-modal-body"} style={{ background: "#0058ff" }}>
              <div id="dbModal" className={`db-container ${this.state.maximizeIOJPopup ? " b9-sisense-max-btn" : " "}`}></div>
            </Modal.Body>
          </Modal>
          <Modal
            show={this.state.showPowerBiModal}
            onHide={handlePowerBiClose}
            className="modal-width"
            dialogClassName={this.state.maximizeIOJPopup ? "maximized-b9-popup LeftmodalTableau" : "modal-90w LeftmodalTableau"}
          >
            <Modal.Header closeButton style={{ borderBottom: "none", background: "#0058ff" }}>
              <FontAwesomeIcon className='maximizeIconIOJPopupB9' style={{ cursor: "pointer" }}
                onClick={() => this.handleMaxMinPopup()}
                color={"#fff"}
                title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
                icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
            </Modal.Header>
            <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-bodyb9" : "iframe-modal-body"} style={{ background: "#0058ff" }}>
              <div className={`db-container ${this.state.maximizeIOJPopup ? " b9-sisense-max-btn" : " "}`} style={{ overflow: "hidden" }}>
                <PowerBIEmbed
                  embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: this.state.powerBiReportIdModal,
                    embedUrl: this.state.powerBiEmbedUrlModal,
                    accessToken: null,    // Keep as empty string, null or undefined
                    tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                    filters: [this.state.powerBiFilterObj],
                    settings: {
                      panes: {
                        bookmarks: {
                          visible: false
                        },
                        fields: {
                          expanded: false
                        },
                        filters: {
                          expanded: false,
                          visible: true
                        },
                        pageNavigation: {
                          visible: false
                        },
                        selection: {
                          visible: true
                        },
                        syncSlicers: {
                          visible: true
                        },
                        visualizations: {
                          expanded: false
                        }
                      }
                    }
                  }}
                  cssClassName={"power-button-modal power-bi-frame"}
                  getEmbeddedComponent={(embeddedReport) => {
                    window.report = embeddedReport;
                    this.props.updatePBToken();
                  }}
                />
              </div>
            </Modal.Body>
          </Modal>


          <Modal
            show={this.state.showPowerBiWithoutTokenBtn}
            onHide={handlePowerBiWithoutTokenClose}
            className="modal-width"
            dialogClassName={this.state.maximizeIOJPopup ? "maximized-b9-popup LeftmodalTableau" : "modal-90w LeftmodalTableau"}
          >
            <Modal.Header closeButton style={{ borderBottom: "none", background: "#0058ff" }}>
              <FontAwesomeIcon className='maximizeIconIOJPopupB9' style={{ cursor: "pointer" }}
                onClick={() => this.handleMaxMinPopup()}
                color={"#fff"}
                title={!this.state.maximizeIOJPopup ? "Maximize" : "Minimize"}
                icon={this.state.maximizeIOJPopup ? faCompressAlt : faExpandAlt} />
            </Modal.Header>
            <Modal.Body className={this.state.maximizeIOJPopup ? "iframe-modal-bodyb9" : "iframe-modal-body"} style={{ background: "#0058ff" }}>
              <div className={`db-container ${this.state.maximizeIOJPopup ? " b9-sisense-max-btn" : " "}`}>
                <PowerBIEmbed
                  embedConfig={{
                    type: 'report',   // Supported types: report, dashboard, tile, visual and qna
                    id: null,
                    embedUrl: this.state.powerBidirectUrlBtn,
                    accessToken: null,    // Keep as empty string, null or undefined
                    tokenType: models.TokenType.Aad, // models.TokenType.Embed,
                    settings: {
                      panes: {
                        bookmarks: {
                          visible: false
                        },
                        fields: {
                          expanded: false
                        },
                        filters: {
                          expanded: false,
                          visible: true
                        },
                        pageNavigation: {
                          visible: false
                        },
                        selection: {
                          visible: true
                        },
                        syncSlicers: {
                          visible: true
                        },
                        visualizations: {
                          expanded: false
                        }
                      }
                    }
                  }}
                  cssClassName={"power-button-modal power-bi-frame"}
                />
              </div>
            </Modal.Body>
          </Modal>
          {/* SVM Dashboard */}
          <Modal
            backdrop="static"
            show={this.state.showSVMPopUp}
            className="modal-width dark_theme_container"
            onHide={this.hideSVMModal}
            dialogClassName="modal-90w Leftmodal"
          >
            <Modal.Header closeButton style={this.state.maximizeIOJDashboard ? { borderBottom: "none", height: "0rem" } : { borderBottom: "none" }}>
              <div className=' m-0 b9ddc5maxwidth'>
                <div className='row'>
                  <div className='col-12 breadcrumbB9 my-3 ml-1'>
                    <a className='Home text-center' onClick={this.hideSVMModal}>Home</a>
                    <a className='Clicked text-center' style={{ fontFamily: "GraphikMedium" }}>Value Meter</a>
                  </div>
                </div>
              </div>
            </Modal.Header>
            <Modal.Body>
            <div style={{ overflow: "auto" }} className={`app-frame-container svm-dark-theme ${this.state.maximizeSVMDashboard ? " maximizedAppStoreB9" : ""}`}>
            <FontAwesomeIcon className='float-right' style={{ cursor: "pointer" }} onClick={() => this.handleMaxMinSVM()} color={"#fff"} title={!this.state.maximizeSVMDashboard ? "Maximize" : "Minimize"} icon={this.state.maximizeSVMDashboard ? faCompressAlt : faExpandAlt} />
                      {(this.state.selectedClient.value?(this.state.selectedClient.value!=0):false)
                       && (this.state.isKnowMore ? (
                          <DashboardDetailedView
                            dbDimensionList={this.state.dbDimensionList} // main data - DashboardProjectDetails
                            dimensionSubDimension={this.state.dimensionSubDimension} // main data - DashboardDimGraph
                            programID={this.state.programID}
                            dashboardCommentary={this.state.dashboardCommentary}
                            reportingYear={this.state.reportingYear}
                            programFiscalYearRange={this.state.programFiscalYearRange}
                            // UserInformation
                            // enterpriseID={this.props.enterpriseID} // default to "", Current User
                            // isSuperAdmin={this.props.isSuperAdmin} // default to false, Set to false for non-SVM
                            // userProfiles={this.props.userProfiles} // default to [], Set to empty array for non-SVM
                            // showBackButton={true} // default to false
                            // showCommentary={true} // default to false
                            noDataMessage={noDataMessage}
                            isOverrideHandleProjectDetails={false}
                            // Dropdown
                            selectedClient={this.state.selectedClient}
                            isInitialized={this.state.isInitialized}
                            isStart={this.state.isStart}
                            isKnowMore={this.state.isKnowMore}
                            dashboardClientDetails={this.state.dashboardClientDetails}
                            dashboardData={this.state.dashboardData}
                            programOptions={this.state.dashboardProgram}
                            selectedProgram={this.state.selectedProgram}
                            yearOptions={this.state.reportingYear}
                            selectedYear={this.state.selectedYear}
                            // For auto select functionality
                            // isDBAutoSelect={this.state.isDBAutoSelect}
                            // progID={this.props.progID}
                            // reportingYr={this.props.reportingYr}
                            // Actions
                            onClickBackBtn={this.onClickBackBtn}
                            // createLog={this.props.createLog} // Logger function for SVM
                            enableLogger={false} // default to false
                            getAuthObj={this.GetAuthObj}
                            // Callback
                            fetchDashboardDataCallback={this.fetchDashboardDataCallback}
                            fetchClientDetailsCallback={this.fetchClientDetailsCallback}
                            onSelectProgramCallback={this.onSelectProgramCallback}
                            setSelectedReportingYearCallback={
                              this.setSelectedReportingYearCallback
                            }
                            getDashboardDimensionsCallback={
                              this.getDashboardDimensionsCallback
                            }
                            setCommentaryRequiredDataCallback={
                              this.setCommentaryRequiredDataCallback
                            }
                            // overrideHandleProjectDetails={this.overrideHandleProjectDetails}
                          />
                        ) : (
                          <SummaryWheelView
                            dimensionSubDimension={this.state.dimensionSubDimension} // main data
                            dashboardProgramDetails={this.state.dashboardProgramDetails}
                            onKnowMore={this.onKnowMore}
                            // isGlobal={false} - default to false when no value
                            // isGlobalPreview={false} - default to false when no value
                            // displayDownloadReport={true} // - default to false
                            displayKnowMore={true} // - default to false
                            // Dropdown
                            selectedClient={this.state.selectedClient}
                            isInitialized={this.state.isInitialized}
                            isStart={this.state.isStart}
                            isKnowMore={this.state.isKnowMore}
                            dashboardClientDetails={this.state.dashboardClientDetails}
                            dashboardData={this.state.dashboardData}
                            programOptions={this.state.dashboardProgram}
                            selectedProgram={this.state.selectedProgram}
                            yearOptions={this.state.reportingYear}
                            selectedYear={this.state.selectedYear}
                            // For auto select functionality
                            // isDBAutoSelect={this.state.isDBAutoSelect}
                            // progID={this.state.progID}
                            // reportingYr={this.state.reportingYr}
                            // Actions
                            onClickBackBtn={this.onClickBackBtn}
                            createLog={this.createLog} // Logger function for SVM, Optional, not required if  enableLogger set to false or not declared
                            enableLogger={false} // default to false
                            getAuthObj={this.GetAuthObj}
                            // Callback
                            fetchDashboardDataCallback={this.fetchDashboardDataCallback}
                            fetchClientDetailsCallback={this.fetchClientDetailsCallback}
                            onSelectProgramCallback={this.onSelectProgramCallback}
                            setSelectedReportingYearCallback={
                              this.setSelectedReportingYearCallback
                            }
                            getDashboardDimensionsCallback={
                              this.getDashboardDimensionsCallback
                            }
                            setCommentaryRequiredDataCallback={
                              this.setCommentaryRequiredDataCallback
                            }
                          />
                           ))
                      } 

                            </div>
            </Modal.Body>
          </Modal>
          {/* newchangeend */}
          <div className='col-4'>
            <h1 className='synopsHeading' style={{ fontFamily: 'GraphikSemibold' }}>SynOps Hub</h1>
            <div className='B9_IntOps_buttons'>
              {((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26)) || (this.props.userDetails.dashboardRole == ADMIN)) && (
                <>
                  <button className='buttonLeftMenu graphikmedium' onClick={() => this.handleTabChange(4, 0)}>
                    <div className="hm-d-flex align-items-center new-intops-button-tabs">
                      <img
                        className="ml-4 buttonIcon"
                        src={require("../../Images/intelligent-icon.svg")}
                      />
                      <h3 className='ml-3' style={{ fontSize: '2rem' }}>
                        {
                          i18n.t(this.state.mainTabs.find(
                            (element) => element.position == 4
                          ).displayName)
                        }
                      </h3>
                    </div>
                  </button>

                  <button className='buttonLeftMenu graphikmedium' onClick={() => this.handleTabChange(3, 0)}>
                    <div className="hm-d-flex align-items-center new-intops-button-tabs">
                      <img
                        className="ml-4 buttonIcon"
                        src={require("../../Images/insightdriven-icon.svg")}
                      />
                      <h3 className='ml-3' style={{ fontSize: '2rem' }}>
                        {
                          i18n.t(this.state.mainTabs.find(
                            (element) => element.position == 3
                          ).displayName)
                        }
                      </h3>
                    </div>
                  </button>
                  <button className='buttonLeftMenu graphikmedium' onClick={() => this.handleTabChange(2, 0)}>
                    <div className="hm-d-flex align-items-center new-intops-button-tabs">
                      <img
                        className="ml-4 buttonIcon"
                        src={require("../../Images/automated-icon.png")}
                      />
                      <h3 className='ml-3' style={{ fontSize: '2rem' }}>
                        {
                          i18n.t(this.state.mainTabs.find(
                            (element) => element.position == 2
                          ).displayName)
                        }
                      </h3>
                    </div>
                  </button>
                  <button className='buttonLeftMenu graphikmedium' onClick={() => this.handleTabChange(1, 0)}>
                    <div className="hm-d-flex align-items-center new-intops-button-tabs">
                      <img
                        className="ml-4 buttonIcon"
                        src={require("../../Images/foundation.svg")}
                      />
                      <h3 className='ml-3' style={{ fontSize: '2rem' }}>
                        {
                          i18n.t(this.state.mainTabs.find(
                            (element) => element.position == 1
                          ).displayName)
                        }
                      </h3>
                    </div>
                  </button>
                </>
              )}
            </div>
          
            <div className=" ml-5 mt-3 hm-d-flex align-items-center new-intops-button-tabs" >
            {((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 59)) || (this.props.userDetails.dashboardRole == ADMIN)) && (
            <img onClick={this.toggleModalAppStore} className='align-items-center' src={require('../../Images/grid.png')} style={{ marginLeft: '1.5rem' }}></img>
            )}

            {((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 32)) || (this.props.userDetails.dashboardRole == ADMIN)) && (this.state.selectedClient.value?(this.state.selectedClient.value!=0):false) && (
              <img onClick={this.toggleModalSVM} className='align-items-center' src={require('../../Images/Primary.svg')} style={{ marginLeft: '2.5rem' }}></img>
              )}
            </div>
          </div>
          <div className='col-8 text-center pt-5'>
            <img className='synopsbackLogo' src={require('../../Images/Asset3.svg')}></img>
          </div>

        </div>
        <div className="profileCntnrDiv-b9 text-left" hidden={this.state.hideProfileHolderDiv}>
          <div className="mb-3">
            <a className="profileCloseBtnAncr" onClick={() => this.handleCloseProfileBtnClick()}>
              <img className="profileCloseBtn-b9" src={require("../../Images/cross.svg")} />
            </a>
          </div>
          <div className="row mt-4">
            <div className="col-10"><h6 className="graphikmedium" style={{ fontSize: "2rem" }}>{'Profile'}</h6></div>
            <div className="col-2 text-right"><img className="profileIcon hidden" src={require("../../Images/edit-grey-icon.svg")} /></div>
            <div className="col-12 mt-4">
              <div className="media">
                <img className="mr-2 profile-img" src={require("../../Images/profileicon .svg")} />
                <div className="media-body">
                  <div>
                    <a target="_blank" href="https://people.accenture.com/" className="ProfileName mt-4 ml-4 userName" style={{ display: "block", fontSize: "1.5rem" }}>{this.props.profileName}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* {((this.props.userDetails.featureIds &&
                              this.props.userDetails.featureIds.some(
                                (val) => val == 28
                              )) || this.props.userDetails.dashboardRole == ADMIN) &&(<div className="row mt-4 pt-1">
                              <div className="col-12" ><h6 className="graphikmedium" style={{cursor:'pointer'}} onClick={()=>this.props.announcementPopUp(true)}>{i18n.t('Announcements')}</h6></div>
                             
                            </div>)} */}
          {/* <div className="row mt-5 pt-1">
            <div className="col-6"><h6 className="graphikmedium" style={{ color: "gray" }}>{'Dark Theme'}</h6></div>
            <div className="col-6" id="switch-hldr-hmcc">
              <div className="switchContainerTheme-b9 disable">
                <label>
                  <input checked={true}
                    className="switchThemeB9" type="checkbox" />
                  <div>
                    <div>
                    </div>
                  </div>
                </label>
              </div>

            </div>
          </div> */}
          <div className="row mt-5 pt-1 profileLangaugeHldr-b9">
            <div className="col-12 mb-2"><h6 className="graphikmedium">{'Language'}</h6></div>
            <div className="dropdown" id="dropdown1">
              <button className={"hmcc-drop-btn-b9 b9-languagedropdown map-filter map-filter-appliedFilters-b9"}

                onClick={() => this.toggleDropdown("Select Language")}
                style={{ cursor: "pointer" }}>
                <div
                  className='FilterType mt-2 graphikregular' style={{ color: "rgb(145, 158, 171)", fontSize: "1.2rem" }}

                >{this.props.app.language}
                </div>
                <img className='mt-2'
                  src={require("../../Images/drop-down-arrow.png")} />{" "}
                <div
                  className='row m-0 mt-2'>
                </div>
              </button>
              <div
                className="b9-languagedropdown-options"
                hidden={this.state.openedDropdown == "Select Language" ? this.state.hideLanguageDiv : true}
              >
                <div
                  id="searchbox"
                  className="hmcc-searchbox"
                  hidden={this.state.openedDropdown == "Select Language" ? this.state.hideLanguageDiv : true}
                >
                  {this.state.openedDropdown == "Select Language" ? (
                    <input
                      type="text"
                      className="b9-languageSearch graphikregular"
                      placeholder={i18n.t('Search') + '..'}
                      id="myInput"
                      onChange={this.onChangeLanguage}
                      value={this.state.searchedLanguageText}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {this.state.languageListFiltered.map(languages => {
                  return (
                    <div id='myDropdownB9' onClick={() => this.onSelectLanguage(languages)} className={
                      this.state.openedDropdown == "Select Language" ? "dropdown-content graphikregular show" : "dropdown-content graphikregular"
                    }>
                      {i18n.t(languages.languageDescription)}
                    </div>
                  )
                })}

              </div>
            </div>

          </div>
          <div
            className="logoutIconB9"
            onClick={() => {
              this.logout();
            }}
          >
            <FontAwesomeIcon icon={faSignOutAlt} />
            <span className="ml-1">  Logout </span>
          </div>
          <div style={{ position: 'absolute', bottom: '1.5vh', width: '95%' }}>
            <hr class="versionAndTermsOfUse" />
            <div className="row  fontChange">
              <div className="col-6">
              {APP_VERSION}
              </div>
              <div className="col-6" style={{ left: '8rem' }}>
                <a target="_blank" href="https://in.accenture.com/protectingaccenture/terms-of-service-and-privacy-notice/" style={{ color: "white" }}>Terms of Use</a>
              </div>
            </div>
          </div>
        </div>
        <div role="dialog" aria-modal="true" className="fade business_modal-holder modal show" tabindex="-1" style={(this.state.hideAnnouncementHolderDiv && !this.state.showAnnouncements) ? { display: "none" } : { display: "block", backdropFilter:"brightness(0.3)" }}></div>
        <div className="announcementCntnrDivB9 graphikmedium arrow-top text-left"
          hidden={this.state.hideAnnouncementHolderDiv && !this.state.showAnnouncements}
          // style={{
          //   minHeight: this.props.announcements.length == 0 ? "12vh" : "27vh"
          // }}
          >
          <div className="row" style={{ height: "4vh" }}>
            <div className="col-12 alertlabel">


              <NavTabs activeKey={this.state.selectedTab} onSelect={this.handleTabSelect} id="uncontrolled-tab-example" className="mb-3 tabFontB9">
                {(((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26)) ||
                  (this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 30)) ||
																													   
                  (this.props.userDetails.dashboardRole == ADMIN))&& products.length > 0) &&
																							



                  <Tab eventKey="Alert" title={
                    products.length > 0?
                    (<>
                    {i18n.t('Alerts ')}
                    <button
                        className="alert-count-button"
                        >
                        {products.length}
                    </button>
                    </>): <>{i18n.t('Alerts')}</>
                    } className="alertsBorderBottom" >
                    <div className="dismissAllBtn" onClick={() => this.getDismissedAllData()} >
                      Dismiss All
                      <img className="dismissAllCloseBtn" src={require("../../Images/drafting-compass Copy.svg")}
                      />
                    </div>
                    <ToolkitProvider
                      keyField="id"
                      data={products}
                      columns={columns}
                      search
                      exportCSV={{
                        fileName: "Alerts.csv",
                      }}
                    >
                      {(props) => (
                        <div>
                          {/* <div  >
                             <ExportCSVButton
                              {...props.csvProps}
                              className="downloadbtn"
                            >
                              <span>
                                <img className="alertDownload" src={require("../../Images/icon_Download.png")} />
                                Download
                                 
                               </span>

                            </ExportCSVButton>  

                          </div> */}
                          <div >

                            <div className="row pagination-alertB9 tableWidthB9">
                              <BootstrapTable
                                id="tableBackground"
                                // keyField='id'
                                bordered={false}
                                hover={true}

                                headerTitle={true}
                                wrapperClasses="responsive"
                                keyField="id"
                                data={products}
                                columns={columns}
                                columnTitle={columnHover}
                                {...props.baseProps}
                                //  ref={(n) => (this.node = n)}
                                pagination={paginationFactory(optionsPag)}
                                rowEvents={rowEvents} />


                            </div>

                          </div>
                        </div>)}
                    </ToolkitProvider>
                  </Tab>}



              {(((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26)) ||
                                (this.props.userDetails.dashboardRole == ADMIN)) && this.props.announcements.length>0) &&
                <Tab eventKey="Announcement" title="Announcements">
                  <div className="row">
                    <div className="col-sm-12 pl-2">
                      <div >
                        <div>
                          {this.props.announcements.length == 0 ?
                            "No new announcements" :
                            <div className="announcement-content">
                              <div className='announcementContent'>
                                {/* {this.state.userDetails.featureIds.includes("26") || this.state.userDetails.dashboardRole == ADMIN ? <> */}
                                {this.props.announcements.map((item) => {
                                  return (<div className="row mb-2">
                                    <div className="col-sm-12">
                                      <div className="announcementDescCntnrMain" style={{ margin: "3px", width: "auto" }} onClick={() => this.handleViewMoreAnnouncementClick()}>
                                        <TextareaAutosize id='textareaHTML' disabled className="announcementDesc mb-0">{item.rawDescription}</TextareaAutosize>
                                        <span className="announcementDate">{dateFormat(item.validFrom, "dd mmm yyyy")}</span>
                                      </div>
                                    </div>
                                  </div>)
                                })}
                                {/* </> : "" } */}
                              </div>
                            </div>}



                          {/* <div className="row my-3">
                            <div className="col-sm-12 text-center">
                              <a className="announcementViewMore">
                                <span onClick={() => this.handleViewMoreAnnouncementClick()}> View More </span>
                              </a>
                            </div>
                          </div> */}
                           <Form className="row announcementPopupFooterTab mr-2 float-right">
                <Form.Group className="formCheck mt-3">
                <Form.Check
                  type="checkbox"
                  style={{color:"#ffffffba", marginTop:"26px !important"}}
                  className="checkboxAlignment graphikregular"
                  checked={this.state.allowAnnouncementAutoPopup}
                  onClick={(e) =>
                    this.handleNotificationChange(e.target.checked)
                  }
                  label="Do not show this again"
                />
              </Form.Group>
            </Form>
                       
                    </div>
                    </div>
                  </div>
                </div>
                </Tab>}
                </NavTabs>
                <div className="alertClose">
                              <a className="announcementCloseBtnAncr" onClick={() => this.handleCloseAnnouncementBtnClick()}>
                                <img className="announcementCloseBtnB9" src={require("../../Images/drafting-compass Copy.svg")} />
                              </a>
                            </div>
            </div>
            </div>



          </div>

          {/* {this.props.showAnnouncements &&
            ((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26)) || (this.props.userDetails.dashboardRole == ADMIN)) && (<Modal show={this.props.showHMtab} onHide={handleAnnouncementClose} backdrop="static"
              dialogClassName="announcement-modalB9"
              className="background-blur"
              aria-labelledby="example-custom-modal-styling-title"
            >
              <Modal.Header
                //closeButton={true}
                className="dashboard-modal-header"
              ></Modal.Header>
              <Modal.Body>
                <div className="row mb-3">
                  <div className="col-sm-6 graphikmedium notificationlabel">{"Announcements"}</div>
                  <div className="col-sm-6">
                    <a className="announcementCloseBtnAncr" onClick={handleAnnouncementClose}>
                      <img className="announcementCloseBtn" src={require("../../Images/drafting-compass Copy.svg")} />
                    </a>
                  </div>
                </div>
                <div className="announcementCntnrPopupDivB9 graphikmedium">
                  <div style={{ display: "none" }}>{this.state.dummyValue}</div>
                  {this.props.announcements.map((item) => {
                    return (<div className="row mb-2">
                      <div className="col-sm-12">
                        <div className="announcementDescCntnr" style={{ marginTop: "5px" }}>
                          <TextareaAutosize id='textareaHTML' disabled className="announcementDesc mb-0">{item.rawDescription}</TextareaAutosize>
                          <span className="announcementDate">{dateFormat(item.validFrom, "dd mmm yyyy")}</span>
                        </div>
                      </div>
                    </div>)
                  })}
                </div>
                <Form className="row announcementPopupFooter mr-2 float-right">
                  <Form.Group className="formCheck">
                    <Form.Check
                      type="checkbox"
                      style={{ color: "#ffffffba", marginTop: "6px" }}
                      className="checkboxAlignment graphikregular"
                      checked={this.state.allowAnnouncementAutoPopup}
                      onClick={(e) =>
                        this.handleNotificationChange(e.target.checked)
                      }
                      label="Do not show this again"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>)} */}


          {this.props.showProfile &&
            ((this.props.userDetails.featureIds
            ) || this.props.userDetails.dashboardRole == ADMIN) && (<Modal show={this.props.showHMtab} onHide={this.handleAnnouncementCloseProfile} backdrop="static"
              dialogClassName="announcement-modalB9"
              aria-labelledby="example-custom-modal-styling-title"
              className="background-blur"
            >
              <Modal.Header
                // closeButton={true}
                className="dashboard-modal-header"
              ></Modal.Header>
              <Modal.Body>
                <div className="row mb-3">
                  <div className="col-sm-6 graphikmedium notificationlabel">{"Announcements"}</div>
                  <div className="col-sm-6">
                    <a className="announcementCloseBtnAncr" onClick={handleAnnouncementClose}>
                      <img className="announcementCloseBtn" src={require("../../Images/drafting-compass Copy.svg")} />
                    </a>
                  </div>
                </div>
                <div className="announcementCntnrPopupDivB9 graphikmedium">
                  <div style={{ display: "none" }}>{this.state.dummyValue}</div>
                  {this.props.announcements.map((item) => {
                    return (<div className="row mb-2">
                      <div className="col-sm-12">
                        <div className="announcementDescCntnr" style={{ marginTop: "5px" }}>
                          <TextareaAutosize id='textareaHTML' disabled className="announcementDesc mb-0">{item.rawDescription}</TextareaAutosize>
                          <span className="announcementDate">{dateFormat(item.validFrom, "dd mmm yyyy")}</span>
                        </div>
                      </div>
                    </div>)
                  })}
                </div>

                <Form className="row announcementPopupFooter mr-2 float-right">
                  <Form.Group className="formCheck">
                    <Form.Check
                      type="checkbox"
                      style={{ color: "#ffffffba", marginTop: "6px" }}
                      className="checkboxAlignment graphikregular"
                      checked={this.state.allowAnnouncementAutoPopup}
                      onClick={(e) =>
                        this.handleNotificationChange(e.target.checked)
                      }
                      label="Do not show this again"
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
            </Modal>)}
          <footer className='row footerb9'>
            <a onClick={() => this.handletoggleBtnClick()}>
              <img className="footerSettings" src={require('../../Images/ProfileSynops.svg')} ></img></a>
            {((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26)) || (this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 30)) || this.props.userDetails.dashboardRole == ADMIN) && (this.state.alertsDataB9.length>0 || ((this.props.userDetails.featureIds && this.props.userDetails.featureIds.some((val) => val == 26) && this.props.announcements.length>0))) ?
              <div><img className="verticalLine"></img>
                <a className="bellIconHldrB9"
                  onClick={() => this.handleAnnouncementBtnClick()}>
                  <img src={require("../../Images/bell.svg")} className="bellIconB9"
                  />
                  {this.state.alertsDataB9.length>0?<button className="bell-count-b9">{this.state.alertsDataB9.length}</button>:""}
                </a>
                <img className="verticalLine"></img> </div> : ""}

            <img className='footerLogo' src={require('../../Images/newlogo_small.svg')}></img>
            <img className='footerLogo' src={require('../../Images/FooterPoweredBy.svg')}></img>


          </footer>
        </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
          app: state.appReducer,

    };
};
const mapDispatchToProps = (dispatch) => {
    return {
          toggleMenuDisplay: (boolean) => {
          dispatch(toggleMenuDisplay(boolean));
        },
        setFilters: (obj) => {
          dispatch(setFilters(obj));
        },
        setClients: (arr) => {
          dispatch(setClients(arr));
        },
        setUserPreferedLanguage: (code, name) => {
          dispatch(setUserPreferedLanguage(code, name));
        },
        setLanguageTranslations: (arr) => {
          dispatch(setLanguageTranslations(arr));
        }
    };
};
        export default connect(mapStateToProps, mapDispatchToProps)(B9MenuComponent);
